
import { getUserList } from "./user/userDetail";
import { getRolesDetails } from "./user/UserRoleDetails";
import { getLandingPageDetail } from "./landingPage/landingPageDetail";
import { getAmbassadors, getSingleAmbassadors } from "./landingPage/ambassadors";
import { getSponsers, getSingleSponsor } from "./landingPage/sponser";
import { getcollaborators, getSingleCollaborator } from "./landingPage/collaborator";
import { getBlogs,getSingleBlogs } from "./landingPage/blog";

export const actionsApi = {
    getUserList,
    getRolesDetails,
    getLandingPageDetail,
    getAmbassadors,
    getSponsers,
    getSingleSponsor,
    getSingleAmbassadors,
    getcollaborators,
    getSingleCollaborator,
    getBlogs,
    getSingleBlogs
}
