// Components
import { LoginForm } from './components';

const LogIn = () => {
    return (
        <LoginForm />
    )
}

export default LogIn;
