// Libraries
import { Typography, Row, Col, Form, Button, Modal, Upload, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

// const getBase64 = (file) =>
//     new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//     });

const Social = () => {
    const { token } = useToken();
    const [form] = Form.useForm();
    const dispatcher = useShareDispatch();
    const { landingPage } = useShareSelector(state => state.landingPageDetail);
    const [isLoading, setIsLoading] = useState(false)

    // const [previewVisible, setPreviewVisible] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [previewTitle, setPreviewTitle] = useState('');

    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'user.jpeg',
            status: 'done',
            url: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
        },
    ]);

    // const handleCancel = () => setPreviewVisible(false);

    // const handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     setPreviewImage(file.url || file.preview);
    //     setPreviewVisible(true);
    //     setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const onFinish = (values) => {
        console.log('Success:', values);
        setIsLoading(true)
        interceptor.axiosPost("post", "/landing/contactAndAccount/addEdit", values, token).then(res => {
            console.log(res)
            setIsLoading(false)
            if (res.status == 200) {
                OpenSuccessNotification("topRight", res.message,)
                //formHead.setFileList(res)
            }
        })
    };




    useEffect(() => {
        setIsLoading(true)
        interceptor.axiosGet("get", "/landing/getAccountAndContactDetail", token, {}).then(res => {
            console.log(res)
            setIsLoading(false)
            if (res.status == 200) {
                debugger
                form.setFieldsValue(res.data.result)
            }
        })
    }, [''])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='home-hero'>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                form={form}
            >
                <Row gutter={[25, 20, 20, 20]} className="about-form mt-30">
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item className='fs-dn' name="_id" >
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input placeholder="Email" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item name="phoneNumber" label="Phone">
                            <Input placeholder="Phone" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item name="facebookLink" label="Facebook Link">
                            <Input placeholder="Facebook Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item name="twitterLink" label="Twitter Link">
                            <Input placeholder="Twitter Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item name="linkedInLink" label="Linkedin Link">
                            <Input placeholder="Linkedin Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item name="instagramLink" label="Instagram Link">
                            <Input placeholder="Instagram Link" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='form-btn mt-10'>
                    <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>submit</Button>
                </div>
            </Form>
        </div>
    )
}

export default Social;
