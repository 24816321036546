import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';

import image from '../assets/images/user.jpg';

const onChange = (checked) => {
    console.log(`switch to ${checked}`);
};

export const columns = [
    {
        title: 'Image',
        dataIndex: 'path',
        render: (text, record) => <img src={text} />,
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Date & Time',
        dataIndex: 'createdAt',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        render: (text, record) => <Switch defaultChecked onChange={onChange} />,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text, record) => {
            return (
                <div className='table-btns'>
                    <div className='table-btn edit-btn'>
                        <Link to='/edit-sponsor'>
                            <Edit2 size={14} />
                        </Link>
                    </div>
                    <div className='table-btn delete-btn'>
                        <Link to='#'>
                            <Trash2 size={14} />
                        </Link>
                    </div>
                </div>
            )
        },
    },
];

export const data = [
    {
        key: '1',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '2',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '3',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '4',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '5',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '6',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '7',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '8',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '9',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
    {
        key: '10',
        name: 'Cristofer Pass',
        dateTime: moment().format('L - h:mm a'),
    },
]