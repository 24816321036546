import { useState } from 'react';
import jwt_decode from "jwt-decode";


export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('@admindata');
        const userToken = JSON.parse(tokenString);
        return userToken ? userToken : null;
    };

    const checkTokenExpirey = () => {
        const userToken = localStorage.getItem('@adminToken');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            decodedToken.exp = new Date(decodedToken.exp * 1000);
            console.log('Token-> ', decodedToken.exp > new Date())
            if (!decodedToken.exp > new Date()) {
                removeToken()
            }
            return decodedToken.exp > new Date() ? userToken : null;

        } else {
            return null
        }
    };

    const [token, setToken] = useState(checkTokenExpirey());
    const [userLoginObject, setUserLoginObject] = useState(getToken() ? getToken().data : null);

    const saveToken = userToken => {
        localStorage.setItem('@admindata', JSON.stringify(userToken));
        localStorage.setItem('@adminToken', userToken.token);

        setToken(localStorage.getItem('@adminToken'));
    };

    const removeToken = () => {
        localStorage.removeItem("@admindata")
        localStorage.removeItem("@adminToken")
    }

    return {
        saveLoginUser: saveToken,
        token,
        removeToken,
        userLoginObject,
    }
}