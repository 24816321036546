// Libraries
import { Table } from 'antd';

// Component

// Styles
import './style.css';

// Assets

const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

const AntTable = ({ columns, data, scroll = 0, rowClassName ,loading}) => {
    return (
        <Table
            rowClassName={rowClassName}
            columns={columns}
            dataSource={data}
            onChange={onChange}
            loading={loading}
            scroll={{
                x: scroll,
            }}
        />
    )
}

export default AntTable;