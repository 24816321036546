// Libraries
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, DatePicker, Select, Button, Modal, Upload } from 'antd';

// Component

// Styles
import './style.css';
import { useParams } from 'react-router-dom';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import domainurl from '../../../../constant/domainurl';
import { useNavigate } from 'react-router-dom';
import OpenErrorNotification from '../../../Notification/errorNotif';

// Assets
const { Option } = Select;

const EditUser = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [fileList, setfileList] = useState();
    const dispatcher = useShareDispatch();
    const navigate = useNavigate();
    const { token } = useToken();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [isToggle, setIsToggle] = useState(false);
    const [model, setModel] = useState();

    const toggleHandler = () => {
        setIsToggle(isToggle => !isToggle);
    }

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Success:', values);
        setIsLoading(true)
        if (fileList[0] && fileList[0].originFileObj) {
            values.file = fileList[0].originFileObj
            uploadImage(values)
        }
        else {
            fetch(model.profilePicPath)
                .then(async response => {
                    const contentType = response.headers.get('content-type')
                    const blob = await response.blob()
                    let file = new File([blob], "image." + model.profilePicPath.split('.').pop(), { contentType })
                    console.log(file)
                    values.file = file
                    uploadImage(values)
                })
        }
    };


    const uploadImage = (item) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", item.file)
        formdata.append("data", JSON.stringify(item));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/admin/userImageUpload/" + item._id, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                console.log(resp)
                if (resp.status == "ok") {
                    if (isToggle) {
                        let model = {
                            "newPassword": item.password,
                            "confirmPassword": item.cPassword,
                            "userId": item.userId
                        }
                        interceptor.axiosPost("Post", "/admin/userChangePassword", model, token).then(obj => {
                            debugger
                            if (obj.data.status == "ok") {
                                OpenSuccessNotification("topRight", "Updated Successfully")
                                dispatcher(actionsApi.getUserList(token))
                                navigate('/users')
                            }
                            setIsLoading(false)
                        })

                    } else {
                        OpenSuccessNotification("topRight", "Updated Successfully")
                        dispatcher(actionsApi.getUserList(token))
                        setIsLoading(false)
                        navigate('/users')
                    }
                }
                else {
                    setIsLoading(false)
                    OpenErrorNotification("topRight", "Error")
                }
            }).catch(err => {
                setIsLoading(false)
            })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getCitySateCountryData = (cId, sId, calback) => {
        fetch(domainurl + '/users/getcountriesCitiesAndState/' + cId + '/' + sId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    const result = res.result;
                    calback(result)
                    // setCountries(result.countries);
                    // setCities(result.cities);
                    // setStates(result.states);
                    //navigate('/signin');
                }

            })
            .catch((error) => {

            });
    }
    const onCountryChange = (event) => {
        console.log('seleceedd cont:', event);
        if (event) {
            let cId = event.toString();
            form.setFieldsValue({
                state: '',
            });
            form.setFieldsValue({
                city: '',
            });
            getCitySateCountryData(cId, -1, data => {
                setStates(data.states);
            })
        }

    };


    const onStateChange = (event) => {
        console.log('seleceedd state:', event);
        form.setFieldsValue({
            city: '',
        });
        let countryId = form.getFieldValue('country');
        let val = countries.find(x => x.name === countryId) ? countries.find(x => x.name === countryId)._id : countryId;
        getCitySateCountryData(val, event, data => {
            setCities(data.cities);
        })
    };

    useEffect(() => {
        if (id) {
            getCitySateCountryData(-1, -1, data => {
                setCountries(data.countries);
                interceptor.axiosGet("get", "/users/getUserInfo/" + id, token).then(res => {
                    console.log(res.data.result)
                    debugger
                    if (res.status == 200) {
                        setModel(res.data.result)

                        setIsLoading(false)
                        form.setFieldsValue(res.data.result)
                        setfileList([{
                            url: res.data.result.profilePicPath
                        }])

                        let countryName = data.countries.find(x => x.name === res.data.result.country) ? data.countries.find(x => x.name === res.data.result.country)._id : res.data.result.country;
                        if (countryName) {
                            form.setFieldsValue({
                                country: countryName,
                            });
                            getCitySateCountryData(countryName, -1, dataState => {
                                setStates(dataState.states);
                                console.log(dataState.states)
                                let stateName = dataState.states.find(x => x.name == res.data.result.state) ? dataState.states.find(x => x.name == res.data.result.state)._id : res.data.result.state
                                form.setFieldsValue({
                                    state: stateName,
                                });
                                getCitySateCountryData(countryName, stateName, dataCity => {
                                    setCities(dataCity.cities);
                                    let cityName = dataCity.cities.find(x => x.name == res.data.result.city) ? dataCity.cities.find(x => x.name == res.data.result.city)._id : res.data.result.city
                                    form.setFieldsValue({
                                        city: cityName,
                                    });

                                })
                            })
                        }

                        // OpenSuccessNotification("topRight", "Deleted Successfully")


                    }
                })
            });
        }

    }, [id])

    return (
        <div className='add-volunteer mt-30'>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24}>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            maxCount={1}
                            className="upload-image"
                            beforeUpload={(file) => {
                                return false
                            }}
                            onChange={(file) => {
                                setfileList(file.fileList);
                            }}
                        >
                            <Button>Upload Image</Button>
                        </Upload>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item className='fs-dn' name="_id" >
                        </Form.Item>
                        <Form.Item className='fs-dn' name="userId" >
                        </Form.Item>
                        <Form.Item name="username" label="Name">
                            <Input placeholder="Input Name" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="phoneNumber" label="Phone">
                            <Input placeholder="Input Phone" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="country" label="Country">
                            <Select onChange={onCountryChange}
                                showSearch
                                placeholder="Select country"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {countries.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="state" label="State">
                            <Select onChange={onStateChange}
                                showSearch
                                placeholder="Select state"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >

                                {states.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="city" label="City">
                            <Select
                                showSearch
                                placeholder="Select city"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {cities.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="facebook" label="Facebook">
                            <Input placeholder="Input Facebook Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="twitter" label="Twitter">
                            <Input placeholder="Input Twitter Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="linkedin" label="Linkedin">
                            <Input placeholder="Input Linkedin Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="instagram" label="Instagram">
                            <Input placeholder="Input Instagram Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="blogLink" label="Blog Link">
                            <Input placeholder="Input Blog Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="websiteLink" label="Website Link">
                            <Input placeholder="Input your website Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="bookingLink" label="Booking Link">
                            <Input placeholder="Input bookingLink Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="liveLink" label="Live Link">
                            <Input placeholder="Input Live Link Link" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="First Address"
                            name="addressLine1"
                        >
                            <Input placeholder="Input First Address" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Second Address"
                            name="addressLine2"
                        >
                            <Input placeholder="Input Second Address" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Third Address"
                            name="addressLine3"
                        >
                            <Input placeholder="Input Third Address" />
                        </Form.Item>
                    </Col>

                </Row> */}
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    {isToggle &&
                        <>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                >
                                    <Input.Password placeholder="Input Password" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="Confirm Password"
                                    name="cPassword"
                                >
                                    <Input.Password placeholder="Confirm Password" />
                                </Form.Item>
                            </Col>
                        </>
                    }
                    <Col span={24}>
                        <div className='form-btn-group'>
                            <div className='form-btn'>
                                <Button type="primary" className='b-p-x' onClick={toggleHandler}>Change Password</Button>
                            </div>
                            <div className='form-btn'>
                                <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>Update</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div >
    )
}

export default EditUser;
