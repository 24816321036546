// Libraries
import { Popconfirm, Spin, Table, Typography } from 'antd';
import {  Button, Col, Form, Row, Input } from 'antd';


// Component
import { columns } from '../../../../data/AmbassadorsData';
import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';
// Styles
import './style.css';
import { AntTable } from '../../../../components';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useEffect, useState } from 'react';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';


// Assets

const { Title } = Typography;

const Ambassadors = () => {
    const { token } = useToken();
    const [loading, setLoading] = useState(false)
    const [filterlist, setFilterlist] = useState(null);
    const columns = [
        {
            title: 'Image',
            dataIndex: 'path',
            render: (text, record) => <img src={text} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Tagline',
            dataIndex: 'tagLine',
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            render: (text) => moment(text).format('L - h:mm a'),
        },
        {
            title: 'Facebook',
            dataIndex: 'facebookUrl',
        },
        {
            title: 'Twitter',
            dataIndex: 'twitterUrl',
        },
        // {
        //     title: 'Linkedin',
        //     dataIndex: 'youtubeUrl',
        // },
        {
            title: 'Instagram',
            dataIndex: 'instagramUrl',
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        // },
        {
            title: 'Active',
            dataIndex: 'isActive',
            render: (text, record) =>

                <Popconfirm
                    title="Are you sure you want to disable.?"
                    onConfirm={() => {
                        setLoading(true)
                        interceptor.axiosPut("put", "/ambassadors/activeInactive/" + record._id + "/" + !text, {}, token).then(res => {
                            console.log(res)
                            if (res.status == 200) {
                                setLoading(false)
                                OpenSuccessNotification("topRight", "updated successfully")
                                dispatcher(actionsApi.getAmbassadors(token))
                            }
                        })
                    }}
                >
                    <Switch key={record._id} checked={text}
                    />
                </Popconfirm>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => {
                return (
                    <div className='table-btns'>
                        <div className='table-btn edit-btn'>
                            <Link to={`/edit-ambassador/${record?._id}`} >
                                <Edit2 size={14} onClick={() => {
                                }} />
                            </Link>
                        </div>
                        <div className='table-btn delete-btn'>
                            <Popconfirm
                                title="Are you sure you want to delete.?"
                                onConfirm={() => {
                                    setLoading(true)
                                    interceptor.axiosDelete("delete", "/ambassadors/delete/" + record._id, {}, token).then(res => {
                                        console.log(res)
                                        if (res.status == 200) {
                                            setLoading(false)
                                            OpenSuccessNotification("topRight", "Deleted Successfully")
                                            dispatcher(actionsApi.getAmbassadors(token))
                                        }
                                    })
                                }}

                            >
                                <Link to='#'>
                                    <Trash2 size={14} />
                                </Link>
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ]
    const [model, setModel] = useState(null)
    const dispatcher = useShareDispatch();
    const { ambassadors, isLoadingAmbassadors } = useShareSelector(state => state.ambassadors);

    const onFinish = (values) => {
        console.log('Success:', values);
        let filter = model
        if (values.name) {
            filter = filter.filter(x => x.name.toLowerCase().includes(values.name.toLowerCase()))
        }
        if (values.tagLine) {
            filter = filter.filter(x => x.tagLine.toLowerCase().includes(values.tagLine.toLowerCase()))
        }
        setFilterlist(filter)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (ambassadors) {
            setModel(ambassadors)
            setFilterlist(ambassadors)
        }
        else {
            dispatcher(actionsApi.getAmbassadors(token))
        }
    }, [ambassadors])
    return (

        <>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={25}>

                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="name"
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="tagLine"
                            >
                                <Input placeholder='Tag Line' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button> */}
                        <Button type="primary" htmlType="submit">Search</Button>
                    </div>
                </Form>
            </div>
         
            <div className='ambassadors-table table-view rounded-box mt-30'>
                <Title className='mb-0' level={4} type="primary">All Ambassadors</Title>
                <div className='ant-table-block'>
                    <Table
                        rowClassName="ambassadors"
                        scroll={{
                            x: 1300,
                        }}
                        columns={columns}
                        dataSource={filterlist}
                        loading={isLoadingAmbassadors || loading}
                    />

                </div>
            </div>
        </>
    )
}

export default Ambassadors;
