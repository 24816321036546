// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { Editambassador } from './components';

// Styles
import './style.css';

// Assets

const EditambassadorPage = () => {
    return (
        <React.Fragment>
            <Header title="Edit Ambassador" home="Home" path="/ambassadors" segmentOne="Ambassador" segmentTwo="Edit Ambassador" />
            <Editambassador />
        </React.Fragment>
    )
}

export default EditambassadorPage;
