import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import interceptor from "../../../shared/interceptor";

export const getUserList = createAsyncThunk(
    "dashboard/userList",

    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())

        interceptor.axiosGet("get", "/admin/userList", object, {

        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
            }


        }).catch(error => {
            dispatch(gettingResponseError(error))
        })

    },
);


const userDetailSlice = createSlice({
    name: "user_list",
    initialState: {
        userList: null,
        status: "idle",
        isLoadingUserList: false,
        error: null,
        isUserRoleError: false,
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.userList = null;
            state.error = action.payload;
            state.isLoadingUserList = false;
            state.isUserRoleError = true;
        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.userList = action.payload;
            state.isLoadingUserList = false;
            state.isUserRoleError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadingUserList = true;
            state.userList = null;
            state.isUserRoleError = false;
        },
    },


});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingUserRoles, gettingResponseError, gettingResponseSuccess } =
    userDetailSlice.actions;

export default userDetailSlice.reducer;
