// Libraries
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Button, Upload } from 'antd';

// Component

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import OpenSuccessNotification from '../../../Notification/successNotif';
import domainurl from '../../../../constant/domainurl';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { useNavigate } from 'react-router';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useParams } from 'react-router-dom';
import interceptor from '../../../../shared/interceptor';

// Assets
const { Option } = Select;

const EditSponsor = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const { token } = useToken();
    const [isLoader, setIsLoader] = useState(false)
    const [fileList, setfileList] = useState();

    const [model, setModel] = useState();
    const navigate = useNavigate();


    const onFinish = (values) => {
        setIsLoader(true)
        if (fileList[0] && fileList[0].originFileObj) {
            values.file = fileList[0].originFileObj
            uploadImage(values)
        }
        else {
            fetch(model.path)
                .then(async response => {
                    debugger
                    const contentType = response.headers.get('content-type')
                    const blob = await response.blob()
                    let file = new File([blob], "image." + model.path.split('.')[1], { contentType })
                    console.log(file)
                    values.file = file
                    uploadImage(values)
                })
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const { editModel } = useShareSelector(state => state.sponsorData);
    const dispatcher = useShareDispatch();

    useEffect(() => {
        if (id) {
            interceptor.axiosGet("get", "/sponsors/" + id, token).then(res => {
                if (res.status == 200) {
                    setIsLoader(false)
                    setModel(res.data.result[0])
                    form.setFieldsValue(res.data.result[0])
                    setfileList([{
                        url: res.data.result[0].path
                    }])
                }
            })
        }

    }, [id])

    const uploadImage = (res) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", res.file)
        formdata.append("data", JSON.stringify(res))
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/sponsors/upload/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoader(false)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    dispatcher(actionsApi.getSponsers(token))

                    navigate('/sponsors')

                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })
    }


    return (
        <div className='add-volunteer mt-30'>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed} form={form} layout="vertical" autoComplete="off">
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24}>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            maxCount={1}
                            fileList={fileList}
                            className="upload-image"
                            beforeUpload={(file) => {
                                return false
                            }}
                            onChange={(file) => {
                                setfileList(file.fileList);
                            }}
                        >
                            <Button>Upload Image</Button>
                        </Upload>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item className='fs-dn' name="_id" >
                        </Form.Item>
                        <Form.Item name="name" label="Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className='form-btn'>
                            <Button type="primary" htmlType='submit' loading={isLoader} className='b-p-x'>Update</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default EditSponsor;
