// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { EditCollaborator } from './components';

// Styles
import './style.css';

// Assets

const EditCollaboratorPage = () => {
    return (
        <React.Fragment>
            <Header title="Edit Collaborator" home="Home" path="/collaborators" segmentOne="Collaborator" segmentTwo="Edit Collaborator" />
            <EditCollaborator />
        </React.Fragment>
    )
}

export default EditCollaboratorPage;
