import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import useToken from "../../../hooks/useToken";
import OpenErrorNotification from "../../../pages/Notification/errorNotif";
import interceptor from "../../../shared/interceptor";

export const getSponsers = createAsyncThunk(
    "sponser/getAll",
    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        interceptor.axiosGet("get", "/sponsors/getAll", object, {}).then(res => {
            console.log(res)
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
                OpenErrorNotification("topRight", res.message)
            }
        }).catch(error => {
            dispatch(gettingResponseError(error))
            OpenErrorNotification("topRight", error)

        })

    },
);


const sponserSlice = createSlice({
    name: "sponser_Page",
    initialState: {

        sponsorData: null,
        status: "idle",
        isSponsorPage: false,
        error: null,
        isError: false,
        editModel: null,
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.sponsorData = null;
            state.error = action.payload;
            state.isSponsorPage = false;
            state.isError = true;

        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.sponsorData = action.payload;
            state.isSponsorPage = false;
            state.isError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isSponsorPage = true;
            state.sponsorData = null;
            state.isError = false;
        },

        getSingleSponsor: (state, action) => {
            state.status = "edit";
            console.log(action.payload);
            state.editModel = action.payload

        },
    }
});

// Action creators are generated for each case reducer function
export const { gettingResponse, gettingDetails, gettingResponseError, gettingResponseSuccess, getSingleSponsor } =
    sponserSlice.actions;

export default sponserSlice.reducer;
