// Libraries
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Component
import { AntTable } from "../../../../components";
import { columns, data } from '../../../../data/LatestUsersData';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import interceptor from '../../../../shared/interceptor';

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const LatestUsers = () => {
    const [model, setModel] = useState(null)
    const navigate = useNavigate();
    const dispatcher = useShareDispatch();
    const { token } = useToken();
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        interceptor.axiosGet("get", "/dashboard/userList", token).then(res => {
            if (res.status == 200) {
                setModel(res.data.result)

            }
        })
    }, [])
    return (
        <div className='table-view rounded-box mt-30'>
            <Title className='mb-0' level={4} type="primary">Latest Registered User</Title>
            <div className='ant-table-block'>
                <AntTable
                    rowClassName="latest-users"
                    scroll={3500}
                    columns={columns}
                    data={model}
                />
            </div>
        </div>
    )
}

export default LatestUsers;
