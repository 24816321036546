// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { CreateCollaborator } from './components';

// Styles
import './style.css';

// Assets

const CreateCollaboratorPage = () => {
    return (
        <React.Fragment>
            <Header title="Create Collaborator" home="Home"  path="/collaborators" segmentOne="Collaborator" segmentTwo="Create Collaborator" />
            <CreateCollaborator />
        </React.Fragment>
    )
}

export default CreateCollaboratorPage;
