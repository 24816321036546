// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { EditUser } from './components';

// Styles
import './style.css';

// Assets

const EditUserPage = () => {
    return (
        <React.Fragment>
            <Header title="Edit User" home="Home" path="/users" segmentOne="User" segmentTwo="Edit User" />
            <EditUser />
        </React.Fragment>
    )
}

export default EditUserPage;
