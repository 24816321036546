// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { CreateBlogPost } from './components';

// Styles
import './style.css';

// Assets

const CreateBlogPage = () => {
    return (
        <React.Fragment>
            <Header title="Create Blog Post" home="Home" segmentOne="Blog" segmentTwo="Create Blog Post"  path="/blog" />
            <CreateBlogPost />
        </React.Fragment>
    )
}

export default CreateBlogPage;
