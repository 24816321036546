// Libraries
import React from "react";

// Components
import { Header } from '../../components';
import { Analytics, RegisterUsers, LatestUsers } from './components';

// Styles
import './style.css';

const Dashboard = () => {
    return (
        <React.Fragment>
            <Header title="Dashboard" home="Home" segmentTwo="Dashboard" path="/" />
            <Analytics />
            <RegisterUsers />
            {/* <LatestUsers /> */}
        </React.Fragment>
    )
}

export default Dashboard;
