// Libraries
import React, { useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Button, Upload } from 'antd';

// Component

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import interceptor from '../../../../shared/interceptor';
import { Navigate, useNavigate } from 'react-router-dom';
import OpenSuccessNotification from '../../../Notification/successNotif';
import domainurl from '../../../../constant/domainurl';
import { useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';

// Assets
const { Option } = Select;

const CreateAmbassador = () => {

    const [form] = Form.useForm();
    const { token } = useToken();
    const [isLoader, setIsLoader] = useState(false)
    const [fileList, setfileList] = useState();
    const dispatcher = useShareDispatch();
    const navigate = useNavigate();
    const onFinish = (values) => {
        setIsLoader(true)
        values._id = values._id ? values._id : "-1"
        console.log(values);
        uploadImage(values)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const uploadImage = (res) => {
        console.log()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", fileList)
        formdata.append("data", JSON.stringify(res))
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/sponsors/upload/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoader(false)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    dispatcher(actionsApi.getSponsers(token))
                    navigate('/sponsors')

                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })
    }


    return (
        <div className='add-volunteer mt-30'>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed} form={form} layout="vertical" autoComplete="off">
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24}>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            maxCount={1}
                            className="upload-image"
                            beforeUpload={(file) => {
                                return false
                            }}
                            onChange={(file) => {
                                setfileList(file.fileList[0].originFileObj);
                            }}
                        >
                            <Button>Upload Image</Button>
                        </Upload>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item className='fs-dn' name="_id" >
                        </Form.Item>
                        <Form.Item name="name" label="Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className='form-btn'>
                            <Button type="primary" htmlType='submit' loading={isLoader} className='b-p-x'>Save</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CreateAmbassador;
