// Libraries
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

// Components
import { Ambassadors } from './components';
import { Header } from '../../components';

// Styles
import './style.css';

// Assets

const AmbassadorsPage = () => {
    return (
        <React.Fragment>
            <Header title="Ambassadors" home="Home" segmentOne="Ambassadors" path="" />
            <div className='create-users create mt-30'>
                <Link to="/create-ambassador">
                    <Button type="primary" className='b-p-x'>Add new Ambassador</Button>
                </Link>
            </div>
            <Ambassadors />
        </React.Fragment>
    )
}

export default AmbassadorsPage;
