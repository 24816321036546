// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { CreateAmbassador } from './components';

// Styles
import './style.css';

// Assets

const CreateAmbassadorPage = () => {
    return (
        <React.Fragment>
            <Header title="Create Ambassador" home="Home" segmentOne="Ambassador" segmentTwo="Create Ambassador"  path="/ambassadors"/>
            <CreateAmbassador />
        </React.Fragment>
    )
}

export default CreateAmbassadorPage;
