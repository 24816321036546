// Libraries
import { Image, Typography, Form, Input, Button, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import logo from '../../../../assets/images/logo.png';
import loginImg from '../../../../assets/images/login.jpg';
import { useState } from 'react';
import domainurl from '../../../../constant/domainurl';
import OpenErrorNotification from '../../../Notification';
import useToken from '../../../../hooks/useToken';


const { Title, Text } = Typography;

const LoginForm = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const { saveLoginUser } = useToken();
    const navigate = useNavigate();



    const onFinish = (values) => {
        console.log(values);
        signInUser(values);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const signInUser = (values) => {
        setIsLoading(true);
        let bodyJson = {
            "email": values.email,
            "password": values.password,
            "isAdmin": true
        }
        fetch(domainurl + '/users/authenticate', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyJson)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    saveLoginUser(res.result);
                    navigate('/dashboard');
                }
                else {
                    if (res.status == "error") {
                        form.setFields([
                            {
                                name: 'password',
                                errors: [res.message],
                            },
                            {
                                name: 'email',
                                errors: [res.message],
                            },
                        ]);

                        OpenErrorNotification("topRight", "Email or Password incorrect", 'Error')

                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    return (
        <div className='auth'>
            <Row align='middle'>
                <Col sm={24} md={12} lg={12} xl={12}>
                    <div className='auth-img'>
                        <Image src={loginImg} alt="Login" preview={false} />
                    </div>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12}>
                    <div className='auth-outer'>
                        <div className='logo'>
                            <Image width={165} src={logo} preview={false} />
                        </div>
                        <div className='auth-card'>
                            <Title level={2} type="primary">Sign In</Title>
                            <Text type="secondary" className='auth-text auth-text-sm'>Weclome to Fitstar, please fill the form to login.</Text>
                            <Form
                                name="basic"
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="email"
                                    label="Your Email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="info@fitstar.com" />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Password Required" />
                                </Form.Item>
                                <div className='auth-meta'>
                                    {/* <Link to='#'>Forgot Password?</Link> */}
                                </div>
                                <Form.Item className='mb-0'>
                                    <Button type="primary" htmlType="submit" loading={isLoading} >signin</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <Text type="secondary" className='footer-text'>Copyright © 2022 fitstar  All rights reserved.</Text>
                </Col>
            </Row>
        </div>
    )
}

export default LoginForm;
