// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { EitBlogPost } from './components';

// Styles
import './style.css';

// Assets

const EditBlogPage = () => {
    return (
        <React.Fragment>
            <Header title="Edit Blog Post" home="Home" path="/blog" segmentOne="Blog" segmentTwo="Edit Blog Post" />
            <EitBlogPost />
        </React.Fragment>
    )
}

export default EditBlogPage;
