
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import LogIn from './pages/login';
import PublicRoute from './routes/PublicRoute';
import PrivateRoutes from './routes/PrivateRoutes';
import { AppLayout } from './components';

import Dashboard from './pages/dashboard';
import UsersPage from './pages/users';
import CreateUserPage from './pages/create-user';
import EditUserPage from './pages/edit-user';
import AmbassadorsPage from './pages/ambassadors';
import CreateAmbassadorPage from './pages/create-ambassador';
import EditambassadorPage from './pages/edit-ambassador';
import SponsorsPage from './pages/sponsors';
import CreateSponsorPage from './pages/create-sponsor';
import EditSponsorPage from './pages/edit-sponsor';
import CollaboratorsPage from './pages/collaborators';
import CreateCollaboratorPage from './pages/create-collaborator';
import EditCollaboratorPage from './pages/edit-collaborator';
import BlogPage from './pages/blog';
import CreateBlogPage from './pages/create-blog-post';
import EditBlogPage from './pages/edit-blog-post';
import LandingPage from './pages/landing';


function Entry() {
    return (
        <Routes>
            <Route path="/" element={<PublicRoute />}>
                <Route path="/" element={<Navigate replace to="login" />} />
                <Route path="/login" element={<LogIn />} />

            </Route>
            <Route path="/" element={<PrivateRoutes />}>
                <Route path="/" element={<AppLayout />}>
                    <Route path="/" element={<Navigate replace to="dashboard" />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="users" element={<UsersPage />} />
                    <Route path="create-user" element={<CreateUserPage />} />
                    <Route path="edit-user/:id" element={<EditUserPage />} />
                    <Route path="ambassadors" element={<AmbassadorsPage />} />
                    <Route path="create-ambassador" element={<CreateAmbassadorPage />} />
                    <Route path="edit-ambassador/:id" element={<EditambassadorPage />} />
                    <Route path="sponsors" element={<SponsorsPage />} />
                    <Route path="create-sponsor" element={<CreateSponsorPage />} />
                    <Route path="edit-sponsor/:id" element={<EditSponsorPage />} />
                    <Route path="collaborators" element={<CollaboratorsPage />} />
                    <Route path="create-collaborator" element={<CreateCollaboratorPage />} />
                    <Route path="edit-collaborator/:id" element={<EditCollaboratorPage />} />
                    <Route path="blog" element={<BlogPage />} />
                    <Route path="create-blog-post" element={<CreateBlogPage />} />
                    <Route path="edit-blog-post/:id" element={<EditBlogPage />} />
                    <Route path="landing" element={<LandingPage />} />
                </Route>
            </Route>

        </Routes>
    )
}

export default Entry;