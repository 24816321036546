// Libraries
import React, { useState } from 'react';
import {
    Layout,
    Menu,
    Dropdown,
    Avatar,
    Image,
    Space,
} from 'antd';
import {
    Menu as HamBurger,
    ArrowRight,
    Home,
    ChevronDown,
    LogOut,
    Users,
    DollarSign,
    Rss,
} from 'react-feather';
import {
    useNavigate,
    Outlet,
    Link
} from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import Logo from '../../assets/images/logo.png';
import UserImg from '../../assets/images/user.jpg';
import useToken from '../../hooks/useToken';
// import HandIcon from '../../assets/images/hand.png';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const items = [
    getItem('Dashboard', '1', <Home size={16} />),
    getItem('Users', '2', <Users size={16} />),
    getItem('Ambassadors', '3', <Users size={16} />),
    getItem('Sponsors', '4', <DollarSign size={16} />),
    getItem('Collaborators', '5', <DollarSign size={16} />),
    getItem('Blog', '6', <Rss size={16} />),
    getItem('landing', '7', <Rss size={16} />),
];

const AppLayout = () => {

    const [collapsed, setCollapsed] = useState(true);
    const navigate = useNavigate();
    const { token, removeToken, userLoginObject } = useToken();


    const handleLogout = () => {

        removeToken();
        navigate('/signin');
    }

    const onClick = (e) => {
        let key = e.key;

        switch (key) {
            case '1':
                navigate('dashboard');
                break;
            case '2':
                navigate('users');
                break;
            case '3':
                navigate('ambassadors');
                break;
            case '4':
                navigate('sponsors');
                break;
            case '5':
                navigate('collaborators');
                break;
            case '6':
                navigate('blog');
                break;
            case '7':
                navigate('landing');
                break;
            case '8':
                break;
            default:
        }
    };
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link to="/login" onClick={handleLogout}>Logout</Link>
                    ),
                    icon: <LogOut size={14} onClick={() => console.log('LogOut')} />,
                },
            ]}
        />
    );
    return (
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width={250}
                className={`sider-sider ${collapsed ? '' : 'sider-close'}`}
            >
                <div className="menu-logo" onClick={() => {
                    navigate('/dashboard');
                }}>
                    <Image preview={false} width={205} src={Logo} />
                </div>
                <Menu
                    onClick={onClick}
                    className='left-menu'
                    theme="dark"
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    items={items}
                />
            </Sider>
            <Layout className={`main-content ${collapsed ? '' : 'main-content-close'}`}>
                <Header className='ant-header'>
                    <div className='ant-header-left'>
                        <div className="black-logo" onClick={() => {
                            navigate('/dashboard');
                        }}>
                            <Image preview={false} width={205} src={Logo} />
                        </div>
                        <div className='ant-header-arrow'>
                            {React.createElement(collapsed ? HamBurger : ArrowRight, {
                                onClick: () => setCollapsed(!collapsed),
                            })}
                        </div>
                    </div>
                    <div className='header-end'>
                        <Dropdown overlay={menu} placement="bottomRight" arrow>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <Avatar
                                        size={40}
                                        src={
                                            <Image
                                                preview={false}
                                                src={userLoginObject.profilePicPath ? userLoginObject.profilePicPath : UserImg}
                                            />
                                        }
                                    />
                                    <span>Admin</span>
                                    <ChevronDown size={10} />
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                </Header>
                <Content className='container'>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}

export default AppLayout;
