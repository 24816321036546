// Libraries
import React, { useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Button, Upload } from 'antd';

// Component

// Styles
import './style.css';
import domainurl from '../../../../constant/domainurl';
import OpenSuccessNotification from '../../../Notification/successNotif';
import { useNavigate } from 'react-router-dom';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { blogCategoryArray } from '../../../../data/BlogData';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// Assets
const { Option } = Select;

const CreateBlogPost = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoader, setIsLoader] = useState(false)
    const { token } = useToken();
    const [authorImage, setauthorImage] = useState();
    const [featuredImage, setfeaturedImage] = useState();
    const [secoundFeaturedImage, setsecoundFeaturedImage] = useState();

    const dispatcher = useShareDispatch();
   
    const [shortDesc, setShortDesc] = useState(
        () => EditorState.createEmpty(),
    );
    const [longDesc, setLongDesc] = useState(
        () => EditorState.createEmpty(),
    );

    const onFinish = (value) => {
        console.log(value);
        setIsLoader(true)
        value._id = value._id ? value._id : "-1"
        value.featuredImage = featuredImage ? featuredImage[0].name.split(".")[0] : null
        value.secoundFeaturedImage = secoundFeaturedImage ? secoundFeaturedImage[0].name.split(".")[0] : null
        value.authorImage = authorImage ? authorImage[0].name.split(".")[0] : null

        const rawContentState = draftToHtml(convertToRaw(shortDesc.getCurrentContent()));
        value.shortDescription = JSON.stringify(rawContentState)
        const rawContentState2 = draftToHtml(convertToRaw(longDesc.getCurrentContent()));
        value.blogDetail = JSON.stringify(rawContentState2)
        console.log(value)
        uploadImage(value)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const uploadImage = (res) => {
        console.log(res)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        if (res.featuredImage)
            formdata.append("image", featuredImage[0].originFileObj)
        if (res.authorImage)
            formdata.append("image", authorImage[0].originFileObj)
        if (res.secoundFeaturedImage)
            formdata.append("image", secoundFeaturedImage[0].originFileObj)
        formdata.append("request", JSON.stringify(res))
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/blogs/create/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoader(false)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    navigate('/blog')
                    dispatcher(actionsApi.getBlogs(token))

                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })
    }


    return (
        <div className='add-volunteer mt-30'>
            <div className='add-volunteer mt-30'>
                <Form form={form} layout="vertical" autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={{
                        xs: 20,
                        sm: 20,
                        md: 24,
                        lg: 30,
                    }}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item className='fs-dn' name="_id" >
                            </Form.Item>
                            <label style={{ marginBottom: '15px', display: 'block' }}>Upload Featured Image</label>
                            <Form.Item name="featuredImage" >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    maxCount={1}
                                    fileList={featuredImage}
                                    className="upload-image"
                                    onChange={(file) => {
                                        setfeaturedImage(file.fileList);
                                    }}
                                    beforeUpload={(file) => {
                                        return false
                                    }}
                                >
                                    <Button>Upload Image</Button>
                                </Upload>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <label style={{ marginBottom: '15px', display: 'block' }}>Upload Author Image</label>
                            <Form.Item name="featuredImage" >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    maxCount={1}
                                    className="upload-image"
                                    fileList={authorImage}
                                    onChange={(file) => {
                                        setauthorImage(file.fileList);
                                    }}
                                    beforeUpload={(file) => {
                                        return false
                                    }}
                                >
                                    <Button>Upload Image</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <label style={{ marginBottom: '15px', display: 'block' }}>Upload Secound Featured Image</label>
                            <Form.Item name="featuredImage" >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    maxCount={1}
                                    fileList={secoundFeaturedImage}
                                    className="upload-image"
                                    onChange={(file) => {
                                        setsecoundFeaturedImage(file.fileList);
                                    }}
                                    beforeUpload={(file) => {
                                        return false
                                    }}
                                >
                                    <Button>Upload Image</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="title" label="Title">
                                <Input placeholder="Title" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="authorName" label="Author">
                                <Input placeholder="Author" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="category" label="Category">
                                <Select placeholder="Category"
                                >
                                    {blogCategoryArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="videoLink" label="Video Link">
                                <Input placeholder="Video Link" />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="dateTime" label="Date & Time">
                            <DatePicker
                                showTime
                                placeholder="Select Date & Time"
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col> */}
                        <Col span={24}>
                            <Form.Item name="shortDescription" label="Short Description">
                                <Editor
                                    editorState={shortDesc}
                                    onEditorStateChange={setShortDesc}
                                />
                                {/* <Input.TextArea allowClear placeholder='Input Short Description' /> */}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="blogDetail" label="Description">
                                <Editor
                                    editorState={longDesc}
                                    onEditorStateChange={setLongDesc}
                                /> 
                                {/* <Input.TextArea allowClear placeholder='Input Description' /> */}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className='form-btn'>
                                <Button type="primary" htmlType='submit' loading={isLoader} className='b-p-x'>Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default CreateBlogPost;
