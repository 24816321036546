// Libraries
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

// Components
import { Users } from './components';
import { Header } from '../../components';

// Styles
import './style.css';

// Assets

const UsersPage = () => {
    return (
        <React.Fragment>
            <Header title="Users" home="Home" path="/" segmentOne="Users" />
            <div className='create-users create mt-30'>
                <Link to="/create-user">
                    <Button type="primary" className='b-p-x'>Add new user</Button>
                </Link>
            </div>
            <Users />
        </React.Fragment>
    )
}

export default UsersPage;
