// Libraries
import { Typography, Row, Col, Form, Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

// Component

// Styles
import './style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import OpenSuccessNotification from '../../../Notification/successNotif';

// Assets

const { Title } = Typography;

const Terms = () => {
    const { token } = useToken();
    const [form] = Form.useForm();
    const dispatcher = useShareDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [model, setModel] = useState(null)

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );


    useEffect(() => {
        setIsLoading(true)
        interceptor.axiosGet("get", "/landing/getTermsAndCondition", token, {}).then(res => {
            console.log(res)
            setIsLoading(false)
            if (res.status == 200 && res.data.result) {
                setModel(res.data.result)
                const contentBlocks = convertFromHTML(JSON.parse(res.data.result.termsAndCondition));
                const contentState = ContentState.createFromBlockArray(
                    contentBlocks.contentBlocks,
                    contentBlocks.entityMap
                );
                setEditorState(EditorState.createWithContent(contentState));
            }
        })
    }, [''])

    const onFinish = (values) => {
        debugger
        const rawContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
       
        console.log(rawContentState)
        console.log('Success:', values);
        setIsLoading(true)
        let item = {
            _id: model?._id ? model?._id : -1,
            termsAndCondition: JSON.stringify(rawContentState)
        }
        console.log('Success:', values);
        interceptor.axiosPost("post", "/landing/termsAndCondition/addEdit", item, token).then(res => {
            console.log(res)
            setIsLoading(false)
            if (res.status == 200) {
                OpenSuccessNotification("topRight", res.message,)
                //formHead.setFileList(res)
            }
        })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='home-hero'>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                form={form}
            >
                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                />
                <div className='form-btn mt-10'>
                    <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>submit</Button>
                </div>
            </Form>
        </div>
    )
}

export default Terms;
