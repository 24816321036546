// Libraries
import { Typography, Table, Popconfirm, Button, Col, Form, Row, Input } from 'antd';

import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';
import { useNavigate } from 'react-router';
// Component
import { columns, data } from '../../../../data/UsersData';

// Styles
import './style.css';
import { useEffect, useState } from 'react';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import useToken from '../../../../hooks/useToken';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';
import { Header } from '../../../../components';

// Assets

const { Title } = Typography;

const Users = () => {
    const [model, setModel] = useState(null)
    const navigate = useNavigate();
    const dispatcher = useShareDispatch();
    const { userList, isLoadingUserList } = useShareSelector(state => state.userList);
    const { token } = useToken();
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [filterlist, setFilterlist] = useState(null);
    const columns = [
        {
            title: 'Image',
            dataIndex: 'profilePicPath',
            render: (text, record) => <img src={text} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'Register Country',
            dataIndex: 'country',
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            render: (text) => moment(text).format('L - h:mm a'),

        },
        {
            title: 'Facebook',
            dataIndex: 'facebook',
        },
        {
            title: 'Twitter',
            dataIndex: 'twitter',
        },
        {
            title: 'Linkedin',
            dataIndex: 'linkedin',
        },
        {
            title: 'Instagram',
            dataIndex: 'instagram',
        },
        {
            title: 'Refered By',
            dataIndex: 'referedBy',
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        // },
        {
            title: 'Active',
            dataIndex: 'isActive',
            render: (text, record) =>
                <Popconfirm
                    title="Are you sure you want to disable.?"
                    onConfirm={() => {
                        setLoading(true)
                        interceptor.axiosPut("put", "/admin/activeInactiveUser/" + record._id + "/" + !text, {}, token).then(res => {
                            console.log(res)
                            if (res.status == 200) {
                                setLoading(false)
                                OpenSuccessNotification("topRight", "updated successfully")
                                dispatcher(actionsApi.getUserList(token))
                            }
                        })
                    }}

                >
                    <Switch key={record._id} checked={text}
                    />
                </Popconfirm>

        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => {
                return (
                    <div className='table-btns'>
                        <div className='table-btn edit-btn'>
                            <Link to={`/edit-user/${record?._id}`} >
                                <Edit2 size={14} onClick={() => {
                                    console.log(record)
                                }} />
                            </Link>
                        </div>
                        <div className='table-btn delete-btn'>

                            <Popconfirm
                                title="Are you sure you want to delete.?"
                                onConfirm={() => {
                                    setLoading(true)
                                    interceptor.axiosDelete("delete", "/admin/userDelete/" + record._id, {}, token).then(res => {
                                        console.log(res)
                                        if (res.status == 200) {
                                            setLoading(false)
                                            OpenSuccessNotification("topRight", "Deleted Successfully")
                                            dispatcher(actionsApi.getUserList(token))
                                        }
                                    })
                                }}

                            >
                                <Link to='#'>
                                    <Trash2 size={14} />
                                </Link>
                            </Popconfirm>

                        </div>
                    </div>
                )
            },
        },
    ];
    useEffect(() => {
        if (userList) {
            console.log(userList)
            setModel(userList)
            setFilterlist(userList)
        }
        else {
            dispatcher(actionsApi.getUserList(token))
        }
    }, [userList])


    const onFinish = (values) => {
        console.log('Success:', values);
        let filter = model
        if (values.name ) {
            filter = filter.filter(x => x.name.toLowerCase().includes(values.name.toLowerCase()))
        }
        if (values.email) {
            filter = filter.filter(x => x.email.toLowerCase().includes(values.email.toLowerCase()))
        }
        if (values.phoneNumber) {
            filter = filter.filter(x => x.phoneNumber.toLowerCase().includes(values.phoneNumber.toLowerCase()))
        }
        setFilterlist(filter)
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row gutter={25}>

                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="name"
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="email"
                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="phoneNumber"
                            >
                                <Input placeholder='Phone Number' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button> */}
                        <Button type="primary" htmlType="submit">Search</Button>
                    </div>
                </Form>
            </div>

           
            <div className='table-view rounded-box mt-30'>
                <Title className='mb-0' level={4} type="primary">Registerd User</Title>
                <div className='ant-table-block'>
                    <Table
                        rowClassName="users"
                        scroll={{
                            x: 1300,
                        }}
                        columns={columns}
                        dataSource={filterlist}
                        loading={isLoadingUserList || loading}
                    />

                </div>
            </div>
        </>

    )
}

export default Users;
