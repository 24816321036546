// Libraries
import { Typography, Row, Col, Form, Button, Modal, Upload, Input, Tabs, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import UserImg from '../../../../assets/images/user.jpg';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';


// Component
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// Styles
import './style.css';
import domainurl from '../../../../constant/domainurl';
import { Trash2 } from 'react-feather';

// Assets

const { Title } = Typography;

// const getBase64 = (file) =>
//     new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//     });

const Home = () => {
    const { token } = useToken();
    const [formHead] = Form.useForm();
    const [formBanner1] = Form.useForm();
    const [formBanner2] = Form.useForm();
    const [formBanner3] = Form.useForm();
    const [formBanner4] = Form.useForm();
    const [fromAbout,] = Form.useForm();
    const [formFooter] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading1, setIsLoading1] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [isLoading3, setIsLoading3] = useState(false)
    // const [previewVisible, setPreviewVisible] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [previewTitle, setPreviewTitle] = useState('');
    const dispatcher = useShareDispatch();
    const { landingPage, isLoadingPage } = useShareSelector(state => state.landingPageDetail);

    const obj = {
        uid: '-1',
        name: 'user.jpeg',
        status: 'done',
        url: UserImg,
    }
    const [fileList, setfileList] = useState([obj]);
    const [fileList1, setfileList1] = useState([obj]);
    const [fileList2, setfileList2] = useState([obj]);
    const [fileList3, setfileList3] = useState([obj]);
    const [fileList4, setfileList4] = useState([obj]);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );


    useEffect(() => {
        debugger
        if (landingPage) {
            // if (landingPage.header) {
            //     formBanner.setFieldsValue()
            // }
            if (landingPage.banners) {
                landingPage.banners[0] ? formBanner1.setFieldsValue(landingPage.banners[0]) : formBanner1.resetFields()
                landingPage.banners[1] ? formBanner2.setFieldsValue(landingPage.banners[1]) : formBanner2.resetFields()
                landingPage.banners[2] ? formBanner3.setFieldsValue(landingPage.banners[2]) : formBanner3.resetFields()
                landingPage.banners[3] ? formBanner4.setFieldsValue(landingPage.banners[3]) : formBanner4.resetFields()

                formBanner1.setFieldsValue({
                    "itemNo": 1
                })
                formBanner2.setFieldsValue({
                    "itemNo": 2
                })
                formBanner3.setFieldsValue({
                    "itemNo": 3
                })
                formBanner4.setFieldsValue({
                    "itemNo": 4
                })


                if (landingPage.banners[0] && landingPage.banners[0].path) {
                    setfileList1([{
                        url: landingPage.banners[0].path
                    }])
                } else {
                    setfileList1([obj])
                }
                if (landingPage.banners[1] && landingPage.banners[1].path) {
                    setfileList2([{
                        url: landingPage.banners[1].path
                    }])
                } else {
                    setfileList2([obj])
                }
                if (landingPage.banners[2] && landingPage.banners[2].path) {
                    setfileList3([{
                        url: landingPage.banners[2].path
                    }])
                } else {
                    setfileList3([obj])
                }
                if (landingPage.banners[3] && landingPage.banners[3].path) {
                    setfileList4([{
                        url: landingPage.banners[3].path
                    }])
                } else {
                    setfileList4([obj])
                }


            }
            if (landingPage.landingAboutPage) {
                fromAbout.setFieldsValue(landingPage.landingAboutPage)
                setfileList([{
                    url: landingPage.landingAboutPage.path
                }])
                if (landingPage.landingAboutPage?.description) {
                    const contentBlocks = convertFromHTML(JSON.parse(landingPage.landingAboutPage.description));
                    const contentState = ContentState.createFromBlockArray(
                        contentBlocks.contentBlocks,
                        contentBlocks.entityMap
                    );
                    setEditorState(EditorState.createWithContent(contentState));
                }

            }
            if (landingPage.landingFooterDetail) {
                formFooter.setFieldsValue(landingPage.landingFooterDetail)
            }
        }
        else {
            dispatcher(actionsApi.getLandingPageDetail(token))

        }
    }, [landingPage])


    useEffect(() => {
        console.log(fileList1)
     }, [fileList1, fileList2, fileList3, fileList4])
    // const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file) => {
        let src = file.url;
        debugger
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };


    const handleChange = (file) => {
        console.log('fileList', file.fileList[0].originFileObj);
        setfileList(file.fileList);
    };

    const onFinishHeader = (values) => {
        console.log('Success:', values);
        values._id = values._id ? values._id : -1
        console.log(values);
        setIsLoading(true)

        interceptor.axiosPost("post", "/landing/contactAndAccount/addEdit", values, token).then(res => {
            setIsLoading(false)

            if (res.status == 200) {
                OpenSuccessNotification("topRight", res.message,)
                //formHead.setfileList1(res)
            }
        })
    };

    const onFinishFailedHeader = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const onFinishBanner = (values) => {
        console.log(values);
        values.itemNo == 1 ? setIsLoading(true) :
            values.itemNo == 2 ? setIsLoading1(true) :
                values.itemNo == 3 ? setIsLoading2(true) :
                    values.itemNo == 4 ? setIsLoading3(true) : setIsLoading(true);


        if (values.itemNo == 1) {
            debugger
            var Promisi1 = new Promise((resolve) => {
                if (fileList1 && fileList1[0] && fileList1[0].originFileObj) {
                    values.fileList1 = fileList1[0].name.split(".")[0];
                    values.fileList1file = fileList1[0].originFileObj
                    resolve();
                }
                else {
                    if (landingPage.banners[0].path) {
                        values.fileList1 = "fileList1"
                        fetch(landingPage.banners[0].path)
                            .then(async response => {
                                const contentType = response.headers.get('content-type')
                                const blob = await response.blob()
                                let file = new File([blob], "authorImage." + landingPage.banners[0].path.split('.').pop(), { contentType })
                                values.fileList1file = file
                                resolve();
                            })
                    } else {
                        resolve();

                    }
                }
            })
        }
        if (values.itemNo == 1) {
            var Promisi1 = new Promise((resolve) => {
                if (fileList1 && fileList1[0] && fileList1[0].originFileObj) {
                    values.fileList1 = fileList1[0].name.split(".")[0];
                    values.fileListfile = fileList1[0].originFileObj
                    resolve();
                }
                else {
                    if (landingPage.banners[values.itemNo - 1] && landingPage.banners[values.itemNo - 1].path) {
                        ImageUrl(landingPage.banners[values.itemNo - 1].path, data => {
                            values.fileListfile = data
                            resolve();
                        })
                    } else {
                        resolve();

                    }
                }
            })
        }
        if (values.itemNo == 2) {
            var Promisi1 = new Promise((resolve) => {
                if (fileList2 && fileList2[0] && fileList2[0].originFileObj) {
                    values.fileList1 = fileList2[0].name.split(".")[0];
                    values.fileListfile = fileList2[0].originFileObj
                    resolve();
                }
                else {
                    if (landingPage.banners[values.itemNo - 1] && landingPage.banners[values.itemNo - 1].path) {
                        ImageUrl(landingPage.banners[values.itemNo - 1].path, data => {
                            values.fileListfile = data
                            resolve();
                        })
                    } else {
                        resolve();

                    }
                }
            })
        }
        if (values.itemNo == 3) {
            var Promisi1 = new Promise((resolve) => {
                if (fileList3 && fileList3[0] && fileList3[0].originFileObj) {
                    values.fileList1 = fileList3[0].name.split(".")[0];
                    values.fileListfile = fileList3[0].originFileObj
                    resolve();
                }
                else {
                    if (landingPage.banners[values.itemNo - 1] && landingPage.banners[values.itemNo - 1].path) {
                        ImageUrl(landingPage.banners[values.itemNo - 1].path, data => {
                            values.fileListfile = data
                            resolve();
                        })
                    } else {
                        resolve();

                    }
                }
            })
        }
        if (values.itemNo == 4) {
            var Promisi1 = new Promise((resolve) => {
                if (fileList4 && fileList4[0] && fileList4[0].originFileObj) {
                    values.fileList1 = fileList4[0].name.split(".")[0];
                    values.fileListfile = fileList4[0].originFileObj
                    resolve();
                }
                else {
                    if (landingPage.banners[values.itemNo - 1] && landingPage.banners[values.itemNo - 1].path) {
                        ImageUrl(landingPage.banners[values.itemNo - 1].path, data => {
                            values.fileListfile = data
                            resolve();
                        })
                    } else {
                        resolve();

                    }
                }
            })
        }
        // values.image = values.itemNo == 1 ? fileList1[0].originFileObj :
        //     values.itemNo == 2 ? fileList2[0].originFileObj :
        //         values.itemNo == 3 ? fileList3[0].originFileObj :
        //             values.itemNo == 4 ? fileList4[0].originFileObj : '';
        Promise.all([Promisi1]).then(item => {
            debugger
            uploadImage(values)
        })
    };

    const ImageUrl = (path, callback) => {
        fetch(path)
            .then(async response => {
                const contentType = response.headers.get('content-type')
                const blob = await response.blob()
                let file = new File([blob], "authorImage." + path.split('.').pop(), { contentType })
                callback(file)
            })
    }

    const onFinishFailedBanner = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onFinishAbout = (values) => {
        console.log('Success:', values);

        console.log(values);
        const rawContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        values.description = JSON.stringify(rawContentState)
        setIsLoading(true)
        interceptor.axiosPost("post", "/landing/aboutPage/addEdit", values, token).then(res => {
            console.log(res)

            if (res.status == 200) {
                debugger

                if (fileList && fileList[0] && fileList[0].originFileObj) {
                    res.data.result.fileList = fileList[0].name.split(".")[0];
                    res.data.result.fileListfile = fileList[0].originFileObj
                    uploadAboutImage(res.data.result)
                }
                else {
                    if (res.data.result.path) {
                        ImageUrl(res.data.result.path, data => {
                            res.data.result.fileListfile = data
                            uploadAboutImage(res.data.result)
                        })

                    } else {
                        uploadAboutImage(res.data.result)

                    }
                }

            }
        })
    };


    const uploadAboutImage = (res) => {
        console.log(res)
        let id = res && res._id ? res._id : -1
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", res.fileListfile)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/landing/aboutPage/upload/" + id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoading(true)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)

                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            })
    }

    const uploadImage = (res) => {
        res._id = res._id ? res._id : -1
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", res.fileListfile)
        formdata.append("data", JSON.stringify(res));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/landing/bannerUpload/" + res.itemNo + "/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)

                }
                dispatcher(actionsApi.getLandingPageDetail(token))
                setIsLoading(false)
                setIsLoading1(false)
                setIsLoading2(false)
                setIsLoading3(false)
            }).catch(err => {
                setIsLoading(false)
                setIsLoading1(false)
                setIsLoading2(false)
                setIsLoading3(false)
            })
    }

    const onFinishFailedAbout = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onFinishFooter = (values) => {
        console.log('Success:', values);
        console.log(values);
        setIsLoading(true)
        interceptor.axiosPost("post", "/landing/footer/addEdit", values, token).then(res => {
            console.log(res)
            setIsLoading(false)
            if (res.status == 200) {
                OpenSuccessNotification("topRight", res.message)
                // formFooter.setfileList1(res)
            }
        })
    };

    const onFinishFailedFooter = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = () => {

    }

    const deleteSlide = (id) => {
        let val = null
        if (id == 1) {
            val = formBanner1.getFieldValue()
            console.log(val)
        }

        if (id == 2) {
            val = formBanner2.getFieldValue()
            console.log(val)
        }
        if (id == 3) {
            val = formBanner3.getFieldValue()
            console.log(val)
        }
        if (id == 4) {
            val = formBanner4.getFieldValue()
            console.log(val)
        }
        if (val._id) {
            interceptor.axiosDelete("DELETE", "/landing/bannerDelete/" + val._id, {}, token).then(res => {
                console.log("resposne", res)
                dispatcher(actionsApi.getLandingPageDetail(token))
            }).catch(error => {
                console.log(error)
            })
        }

    }

    return (
        <div className='home-hero'>
            <div className='fs-fitness-directory'>
                <div className='fd-tab'>
                    {isLoadingPage ? <div className='fs-spin'><Spin /></div> : <Tabs defaultActiveKey="about" onChange={onChange} type="card">
                        {/* <Tabs.TabPane tab="Header Details" key="Header Details">
                            <Form
                                name="basic"
                                onFinish={onFinishHeader}
                                onFinishFailed={onFinishFailedHeader}
                                autoComplete="off"
                                layout="vertical"
                                initialValues={null}
                            >
                                <Title level={5}>Header Details</Title>
                                <Row gutter={[25, 20, 20, 20]} className="about-form mt-30">
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item name="email" label="Email">
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item name="phoneNumber" label="Phone">
                                            <Input placeholder="Phone" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item name="facebookLink" label="Facebook Link">
                                            <Input placeholder="Facebook Link" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item name="twitterLink" label="Twitter Link">
                                            <Input placeholder="Twitter Link" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item name="linkedInLink" label="Linkedin Link">
                                            <Input placeholder="Linkedin Link" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item name="instagramLink" label="Instagram Link">
                                            <Input placeholder="Instagram Link" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='form-btn mt-10'>
                                    <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>submit</Button>
                                </div>
                            </Form>
                        </Tabs.TabPane> */}
                        <Tabs.TabPane tab="Banner Details" key="Banner Details">

                            <Title level={5} className="mt-30">Banner Details</Title>
                            <Row gutter={[25, 20, 20, 20]}>
                                <Col xs={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                    <Form
                                        name="basic"
                                        onFinish={onFinishBanner}
                                        onFinishFailed={onFinishFailedBanner}
                                        autoComplete="off"
                                        layout="vertical"
                                        form={formBanner1}

                                    >
                                        <div className='hero-post-card'>
                                            <Form.Item name="image"
                                                rules={[
                                                    {
                                                        required: fileList1[0].url ? false : true,
                                                        message: 'Please upload image',
                                                    },
                                                ]} >

                                                <Upload
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={fileList1}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => {
                                                        console.log(file)
                                                        setfileList1(file.fileList);
                                                    }}
                                                    maxCount={1}
                                                    className="hero-img"
                                                    beforeUpload={(file) => {
                                                        return false
                                                    }
                                                    }
                                                >
                                                    {fileList1.length >= 8 ? null : <span>upload banner image</span>}
                                                </Upload>
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="_id" >
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="itemNo" value="1">
                                            </Form.Item>
                                            <Form.Item name="title" >
                                                <Input.TextArea name='title' placeholder='Enter Banner Title' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="tag" >
                                                <Input name='tag' placeholder='Enter Banner Tag' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="description" >
                                                <Input.TextArea name='description' placeholder='Enter Banner Description' className='w-100 mt-10' />
                                            </Form.Item>
                                            <div className='form-btn mt-10'>
                                                <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>submit</Button>
                                                <Button type="primary" className='b-p-x ml-2' onClick={() => deleteSlide(1)}>    <Trash2 size={18} /></Button>
                                            </div>
                                        </div>

                                    </Form>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                    <Form
                                        name="basic"
                                        onFinish={onFinishBanner}
                                        onFinishFailed={onFinishFailedBanner}
                                        autoComplete="off"
                                        layout="vertical"
                                        form={formBanner2}

                                    >
                                        <div className='hero-post-card'>
                                            <Form.Item name="image"
                                                rules={[
                                                    {
                                                        required: fileList2[0].url ? false : true,
                                                        message: 'Please upload image',
                                                    },
                                                ]} >
                                                <Upload
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={fileList2}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => {
                                                        setfileList2(file.fileList);
                                                    }}
                                                    maxCount={1}
                                                    className="hero-img"
                                                    beforeUpload={(file) => {
                                                        return false
                                                    }
                                                    }
                                                >
                                                    {fileList1.length >= 8 ? null : <span>upload banner image</span>}
                                                </Upload>
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="_id" >
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="itemNo" value="2">
                                            </Form.Item>
                                            <Form.Item name="title" >
                                                <Input.TextArea name='title' placeholder='Enter Banner Title' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="tag" >
                                                <Input name='tag' placeholder='Enter Banner Tag' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="description" >
                                                <Input.TextArea name='description' placeholder='Enter Banner Description' className='w-100 mt-10' />
                                            </Form.Item>
                                            <div className='form-btn mt-10'>
                                                <Button type="primary" htmlType='submit' loading={isLoading1} className='b-p-x'>submit</Button>
                                                <Button type="primary" className='b-p-x ml-2' onClick={() => deleteSlide(2)}>    <Trash2 size={18} /></Button>
                                            </div>
                                        </div>
                                    </Form>

                                </Col>
                                <Col xs={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                    <Form
                                        name="basic"
                                        onFinish={onFinishBanner}
                                        onFinishFailed={onFinishFailedBanner}
                                        autoComplete="off"
                                        layout="vertical"
                                        form={formBanner3}

                                    >
                                        <div className='hero-post-card'>
                                            <Form.Item name="image"
                                                rules={[
                                                    {
                                                        required: fileList3[0].url ? false : true,
                                                        message: 'Please upload image',
                                                    },
                                                ]} >
                                                <Upload
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={fileList3}
                                                    // onPreview={handlePreview}
                                                    onChange={(file) => {
                                                        setfileList3(file.fileList);
                                                    }}
                                                    maxCount={1}
                                                    className="hero-img"
                                                    beforeUpload={(file) => {
                                                        return false
                                                    }
                                                    }
                                                >
                                                    {fileList1.length >= 8 ? null : <span>upload banner image</span>}
                                                </Upload>
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="_id">
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="itemNo" value="3">
                                            </Form.Item>
                                            <Form.Item name="title" >
                                                <Input.TextArea name='title' placeholder='Enter Banner Title' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="tag" >
                                                <Input name='tag' placeholder='Enter Banner Tag' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="description" >
                                                <Input.TextArea name='description' placeholder='Enter Banner Description' className='w-100 mt-10' />
                                            </Form.Item>
                                            <div className='form-btn mt-10'>
                                                <Button type="primary" htmlType='submit' loading={isLoading2} className='b-p-x'>submit</Button>
                                                <Button type="primary" className='b-p-x ml-2' onClick={() => deleteSlide(3)}>    <Trash2 size={18} /></Button>
                                            </div>
                                        </div>
                                    </Form>

                                </Col>
                                <Col xs={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                    <Form
                                        name="basic"
                                        onFinish={onFinishBanner}
                                        onFinishFailed={onFinishFailedBanner}
                                        autoComplete="off"
                                        layout="vertical"
                                        form={formBanner4}

                                    >
                                        <div className='hero-post-card'>
                                            <Form.Item name="image"
                                                rules={[
                                                    {
                                                        required: fileList4[0].url ? false : true,
                                                        message: 'Please upload image',
                                                    },
                                                ]} >
                                                <Upload
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={fileList4}
                                                    // onPreview={handlePreview}
                                                    onChange={(file) => {
                                                        setfileList4(file.fileList);
                                                    }}
                                                    maxCount={1}
                                                    className="hero-img"
                                                    beforeUpload={(file) => {
                                                        return false
                                                    }
                                                    }
                                                >
                                                    {fileList1.length >= 8 ? null : <span>upload banner image</span>}
                                                </Upload>
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="_id" >
                                            </Form.Item>
                                            <Form.Item className='fs-dn' name="itemNo" >
                                                <Input name='itemNo' className='fs-dn' value="4" />

                                            </Form.Item>
                                            <Form.Item name="title" >
                                                <Input.TextArea name='title' placeholder='Enter Banner Title' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="tag" >
                                                <Input name='tag' placeholder='Enter Banner Tag' className='w-100 mt-10' />
                                            </Form.Item>
                                            <Form.Item name="description" >
                                                <Input.TextArea name='description' placeholder='Enter Banner Description' className='w-100 mt-10' />
                                            </Form.Item>
                                            <div className='form-btn mt-10'>
                                                <Button type="primary" htmlType='submit' loading={isLoading3} className='b-p-x'>submit</Button>
                                                <Button type="primary" className='b-p-x ml-2' onClick={() => deleteSlide(4)}>    <Trash2 size={18} /></Button>
                                            </div>
                                        </div>
                                    </Form>

                                </Col>

                            </Row>


                        </Tabs.TabPane>
                        <Tabs.TabPane tab="About Page Details" key="About Page Details">
                            <Form
                                name="basic"
                                onFinish={onFinishAbout}
                                onFinishFailed={onFinishFailedAbout}
                                autoComplete="off"
                                layout="vertical"
                                form={fromAbout}

                            >
                                <Title level={5} className="mt-30">About Page Details</Title>
                                <Row gutter={[25, 20, 20, 20]} className="about-form">
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                        <Form.Item className='fs-dn' name="_id">
                                        </Form.Item>
                                        <div className='hero-post-card'>
                                            <Upload
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={handlePreview}
                                                onChange={(file) => {
                                                    setfileList(file.fileList);
                                                }}
                                                maxCount={1}
                                                className="hero-img"
                                                beforeUpload={(file) => {
                                                    return false
                                                }}

                                            >
                                                {fileList1.length >= 8 ? null : <span>upload About image</span>}
                                            </Upload>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={16} xxl={18}>
                                        <Row gutter={[25, 20, 20, 20]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                                                <Form.Item name="title" label="About Us Title">

                                                    <Input placeholder="About Us Title" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                                                <Form.Item name="heading" label="About Us Heading">
                                                    <Input placeholder="About Us Heading" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                                                <Form.Item name="point1" label="About Us Point 1">
                                                    <Input placeholder="About Us Point 1" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                                                <Form.Item name="point2" label="About Us Point 2">
                                                    <Input placeholder="About Us Point 2" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                                                <Form.Item name="point3" label="About Us Point 3">
                                                    <Input placeholder="About Us Point 3" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                                <Form.Item name="description" label="About Us Description">
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={setEditorState}
                                                    />
                                                    {/* <Input.TextArea placeholder="About Us Description" /> */}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className='form-btn mt-10'>
                                    <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>submit</Button>
                                </div>
                            </Form>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Footer Details" key="Footer Details">
                            <Form
                                name="basic"
                                onFinish={onFinishFooter}
                                onFinishFailed={onFinishFailedFooter}
                                autoComplete="off"
                                layout="vertical"
                                form={formFooter}

                            >
                                <Title level={5} className="mt-30">Footer Details</Title>
                                <Row gutter={[25, 20, 20, 20]} className="about-form mt-30">
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <Form.Item className='fs-dn' name="_id">
                                        </Form.Item>
                                        <Form.Item name="copyRight" label="Copyright">
                                            <Input placeholder="Copyright" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='form-btn mt-10'>
                                    <Button type="primary" htmlType='submit' loading={isLoading} className='b-p-x'>submit</Button>
                                </div>
                            </Form>

                        </Tabs.TabPane>

                    </Tabs>}
                </div>

            </div>

        </div>
    )
}

export default Home;
