// Libraries
import { Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";

// Component
import Entry from './Entry';
import { store } from './shared';
// Styles
import './App.less';

// Assets

function App() {
  return (

    <Provider store={store}>
      <Entry />
    </Provider>
  );
}

export default App;
