// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { EditSponsor } from './components';

// Styles
import './style.css';

// Assets

const EditSponsorPage = () => {
    return (
        <React.Fragment>
            <Header title="Edit Sponsor" home="Home" path="/sponsors" segmentOne="Sponsor" segmentTwo="Edit Sponsor" />
            <EditSponsor />
        </React.Fragment>
    )
}

export default EditSponsorPage;
