import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import useToken from "../../../hooks/useToken";
import OpenErrorNotification from "../../../pages/Notification/errorNotif";
import interceptor from "../../../shared/interceptor";

export const getBlogs = createAsyncThunk(
    "blogs/get",
    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        interceptor.axiosGet("get", "/blogs/get", object, {}).then(res => {
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
                OpenErrorNotification("topRight", res.message)
            }
        }).catch(error => {
            dispatch(gettingResponseError(error))
            OpenErrorNotification("topRight", error)

        })

    },
);


const blogsSlice = createSlice({
    name: "blogs",
    initialState: {

        blogs: null,
        status: "idle",
        isLoadingblogs: false,
        error: null,
        isError: false,
        editModel: null
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.blogs = null;
            state.error = action.payload;
            state.isLoadingblogs = false;
            state.isError = true;

        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.blogs = action.payload;
            state.isLoadingblogs = false;
            state.isError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadingblogs = true;
            state.blogs = null;
            state.isError = false;
        },
        getSingleBlogs: (state, action) => {
            state.editModel = null
            state.status = "edit";
            console.log(action.payload);
            state.editModel = action.payload

        },
    },

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingResponseError, gettingResponseSuccess, getSingleBlogs } =
blogsSlice.actions;

export default blogsSlice.reducer;
