import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import useToken from "../../../hooks/useToken";
import OpenErrorNotification from "../../../pages/Notification/errorNotif";
import interceptor from "../../../shared/interceptor";

export const getcollaborators = createAsyncThunk(
    "collaborator/getAll",
    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        interceptor.axiosGet("get", "/collaborators/getAll", object, {}).then(res => {
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
                OpenErrorNotification("topRight", res.message)
            }
        }).catch(error => {
            dispatch(gettingResponseError(error))
            OpenErrorNotification("topRight", error)

        })

    },
);


const collaboratorSlice = createSlice({
    name: "collaborator",
    initialState: {

        collaborators: null,
        status: "idle",
        isLoadingCollaborator: false,
        error: null,
        isError: false,
        editModel: null
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.collaborators = null;
            state.error = action.payload;
            state.isLoadingCollaborator = false;
            state.isError = true;

        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.collaborators = action.payload;
            state.isLoadingCollaborator = false;
            state.isError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadingCollaborator = true;
            state.collaborator = null;
            state.isError = false;
        },
        getSingleCollaborator: (state, action) => {
            debugger
            state.editModel = null
            state.status = "edit";
            console.log(action.payload);
            state.editModel = action.payload

        },
    },

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingResponseError, gettingResponseSuccess, getSingleCollaborator } =
    collaboratorSlice.actions;

export default collaboratorSlice.reducer;
