// Libraries
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, DatePicker, Select, Button, Modal, Upload } from 'antd';

// Component

// Styles
import './style.css';
import { useParams } from 'react-router-dom';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import domainurl from '../../../../constant/domainurl';
import { useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
const { Option } = Select;
const ROLE_OPTIONS = [{ "center": 'Fitness Center', "key": "FC" }, { "center": 'Fitness Model', "key": "FM" }, { "center": 'Fitness Professional', "key": "FP" }];

const CreateUser = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [fileList, setfileList] = useState();
    const dispatcher = useShareDispatch();
    const { token } = useToken();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [isToggle, setIsToggle] = useState(false);
    const toggleHandler = () => {
        setIsToggle(isToggle => !isToggle);
    }

    const onFinish = (values) => {
        let model = {
            _id: -1,
            cPassword: values.cPassword,
            defaultRole: values.defaultRole,
            city: values.city,
            country: values.country,
            description: values.description,
            email: values.email,
            facebook: values.facebook,
            instagram: values.instagram,
            linkedin: values.linkedin,
            password: values.password,
            phoneNumber: values.phoneNumber,
            userRole: values.userRole,
            state: values.state,
            twitter: values.twitter,
            blogLink: values.blogLink,
            websiteLink: values.websiteLink,
            bookingLink: values.bookingLink,
            liveLink: values.liveLink,
            username: values.username,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            addressLine3: values.addressLine3
        }
        console.log('Success:', model);

        setIsLoading(true)
        uploadImage(model)
    };


    const uploadImage = (res) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", fileList[0].originFileObj)
        formdata.append("data", JSON.stringify(res));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/admin/userImageUpload/-1", requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    dispatcher(actionsApi.getUserList(token))
                    navigate('/users')

                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getCitySateCountryData = (cId, sId, calback) => {
        fetch(domainurl + '/users/getcountriesCitiesAndState/' + cId + '/' + sId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    const result = res.result;
                    calback(result)
                }

            })
            .catch((error) => {

            });
    }
    const onCountryChange = (event) => {
        console.log('seleceedd cont:', event);
        if (event) {
            let cId = event.toString();
            form.setFieldsValue({
                state: '',
            });
            form.setFieldsValue({
                city: '',
            });
            getCitySateCountryData(cId, -1, data => {
                setStates(data.states);
            })
        }

    };


    const onStateChange = (event) => {
        console.log('seleceedd state:', event);
        form.setFieldsValue({
            city: '',
        });
        let countryId = form.getFieldValue('country');
        let val = countries.find(x => x.name === countryId) ? countries.find(x => x.name === countryId)._id : countryId;
        getCitySateCountryData(val, event, data => {
            setCities(data.cities);
        })
    };


    useEffect(() => {
        getCitySateCountryData(-1, -1, data => {
            setCountries(data.countries);
        })
    }, [])


    return (
        <div className='add-volunteer mt-30'>
            <Form form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24}>
                        <Form.Item name="profilePicPath"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload image',
                                },
                            ]}

                        >
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                maxCount={1}
                                className="upload-image"
                                beforeUpload={(file) => {
                                    return false
                                }}
                                onChange={(file) => {
                                    setfileList(file.fileList);
                                }}
                            >
                                <Button>Upload Image</Button>
                            </Upload>
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="username" label="Name">
                            <Input placeholder="Input Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="email" label="Email">
                            <Input placeholder="Input Email" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="phoneNumber" label="Phone">
                            <Input placeholder="Input Phone" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Password"
                            name="password"
                        >
                            <Input.Password placeholder="Input Password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Confirm Password"
                            name="cPassword"
                        >
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="userRole" label="Role">
                            <Select
                                mode="multiple"
                                placeholder="Select a Role"
                                showArrow
                            >
                                {ROLE_OPTIONS.map((item) => (
                                    <Select.Option key={item.key} value={item.key}>
                                        {item.center}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="defaultRole" label="Default Role">
                            <Select
                                placeholder="Select Default Role"
                                showArrow
                            >
                                {ROLE_OPTIONS.map((item) => (
                                    <Select.Option key={item.key} value={item.key}>
                                        {item.center}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="country" label="Country">



                            <Select onChange={onCountryChange}
                                showSearch
                                placeholder="Select country"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {countries.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="state" label="State">
                            <Select onChange={onStateChange}
                                showSearch
                                placeholder="Select state"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >

                                {states.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="city" label="City">
                            <Select
                                showSearch
                                placeholder="Select city"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {cities.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="facebook" label="Facebook">
                            <Input placeholder="Input Facebook Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="twitter" label="Twitter">
                            <Input placeholder="Input Twitter Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="linkedin" label="Linkedin">
                            <Input placeholder="Input Linkedin Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="instagram" label="Instagram">
                            <Input placeholder="Input Instagram Link" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="blogLink" label="Blog Link">
                            <Input placeholder="Input Blog Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="websiteLink" label="Website Link">
                            <Input placeholder="Input your website Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="bookingLink" label="Booking Link">
                            <Input placeholder="Input bookingLink Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="liveLink" label="Live Link">
                            <Input placeholder="Input Live Link Link" />
                        </Form.Item>
                    </Col>

                    {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="First Address"
                            name="addressLine1"
                        >
                            <Input placeholder="Input First Address" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Second Address"
                            name="addressLine2"
                        >
                            <Input placeholder="Input Second Address" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Third Address"
                            name="addressLine3"
                        >
                            <Input placeholder="Input Third Address" />
                        </Form.Item>
                    </Col> */}

                    <Col span={24}>
                        <Form.Item name="description" label="Description">
                            <Input.TextArea allowClear placeholder='Input Description' />
                        </Form.Item>
                    </Col>


                    <Col span={24}>
                        <div className='form-btn'>
                            <Button type="primary" htmlType="submit" className='b-p-x'>Save</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CreateUser;
