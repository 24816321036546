// Libraries
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

// Components
import { Blog } from './components';
import { Header } from '../../components';

// Styles
import './style.css';

// Assets

const BlogPage = () => {
    return (
        <React.Fragment>
            <Header title="Blog" home="Home" path="/" segmentOne="Blog" />
            <div className='create-blog create mt-30'>
                <Link to="/create-blog-post">
                    <Button type="primary" className='b-p-x'>Add Blog Post</Button>
                </Link>
            </div>
            <Blog />
        </React.Fragment>
    )
}

export default BlogPage;
