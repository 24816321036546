// Libraries
import React, { useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Button, Upload } from 'antd';

// Component
import interceptor from "../../../../shared/interceptor";

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import { Navigate, useNavigate } from 'react-router-dom';
import domainurl from '../../../../constant/domainurl';
import OpenSuccessNotification from '../../../Notification/successNotif';
import { useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenErrorNotification from '../../../Notification/errorNotif';

// Assets
const { Option } = Select;

const CreateAmbassador = () => {

    const [form] = Form.useForm();
    const { token } = useToken();
    const [isLoader, setIsLoader] = useState(false)
    const [fileList, setfileList] = useState();
    const dispatcher = useShareDispatch();
    const navigate = useNavigate();
    const onFinish = (values) => {
        console.log(values);
        debugger
        if (!fileList ) {
            OpenErrorNotification("topRight", "Error! Please upload image")
            return
        }
        setIsLoader(true)
        interceptor.axiosPost("post", "/ambassadors/create", values, token).then(res => {
            console.log(res)
            if (res.status == 200) {
                uploadImage(res.data.result[0])
            }
            else {
                setIsLoader(false)
                OpenErrorNotification("topRight", "Error! Invalid data")
            }
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const uploadImage = (res) => {
        console.log(res)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", fileList)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/ambassadors/upload/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoader(false)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    dispatcher(actionsApi.getAmbassadors(token))
                    navigate('/ambassadors')

                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })
    }

    return (
        <div className='add-volunteer mt-30'>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                layout="vertical"
                autoComplete="off">
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24}>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            maxCount={1}
                            className="upload-image"
                            onChange={(file) => {
                                setfileList(file.fileList[0].originFileObj);
                            }}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            <Button>Upload Image</Button>
                        </Upload>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item className='fs-dn' name="_id" >
                        </Form.Item>
                        <Form.Item name="name" label="Name"
                         rules={[
                            {
                                required: true,
                                message: 'Please Enter Name',
                            },
                        ]} >
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="tagLine" label="Tagline" 
                         rules={[
                            {
                                required: true,
                                message: 'Please Enter Tagline',
                            },
                        ]} >
                            <Input placeholder="Tagline" />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="dateTime" label="Date & Time">
                            <DatePicker
                                showTime
                                placeholder="Select Date & Time"
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="facebookUrl" label="Facebook"
                         >
                            <Input placeholder="Facebook Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="twitterUrl" label="Twitter"
                         >
                            <Input placeholder="Twitter Link" />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="linkedinUrl" label="Linkedin">
                            <Input placeholder="Linkedin Link" />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="instagramUrl" label="Instagram"
                       >
                            <Input placeholder="Instagram Link" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="description" label="Description"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Description',
                            },
                        ]}>
                            <Input.TextArea allowClear placeholder='Description' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className='form-btn'>
                            <Button type="primary" htmlType='submit' loading={isLoader} className='b-p-x'>Save</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CreateAmbassador;
