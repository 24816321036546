// Libraries
import React, { useEffect, useState } from "react";
import { Typography } from 'antd';
import ReactApexChart from "react-apexcharts";

// Component

// Styles
import './style.css';
import { useShareDispatch } from "../../../../shared";
import useToken from "../../../../hooks/useToken";
import interceptor from "../../../../shared/interceptor";
import { monthNames } from "../../../../data/BlogData";
import domainurl from "../../../../constant/domainurl";

// Assets

const { Title } = Typography;


const RegisterUsers = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatcher = useShareDispatch();
    const { token } = useToken();

    const [item, setItem] = useState(null);
    const [count, setCount] = useState([]);
    const [value, setValue] = useState([]);
    const d = new Date();

    useEffect(() => {
        setIsLoading(true)
        interceptor.axiosGet("get", "/dashboard/userGraphData", token).then(res => {
            if (res.status == 200) {
                setIsLoading(false)
                setCount(res.data.result.counts)
                setValue(res.data.result.values)
                console.log(count)
                console.log(value)

            }
        })
    }, [])
    const series = [{
        name: 'Users',
        data: count
    }]
    const options = {
        chart: {
            type: 'bar',
            height: 500,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: false,
                columnWidth: '10%',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,

        },
        colors: ["#FF1A1A"],
        xaxis: {
            categories: value
        },
        yaxis: {
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return  val 
                }
            }
        }
    }

    return (
        <div className='monthly-register-users rounded-box mt-30 pb-0'>
            <Title className='mb-0' level={4} type="primary">Monthly Register User</Title>

            <ReactApexChart
                className="bar-chart"
                options={options}
                series={series}
                type="bar"
                height={500}
            />
        </div>
    )
}

export default RegisterUsers;
