
import { Button, Divider, notification, Space } from 'antd';
import React from 'react';

//topRight
//topLeft
const OpenInfoNotification = (placement, desciption) => {
    notification.info({
        message: 'Info',
        description: desciption,
        placement,
    });
};

export default OpenInfoNotification;