// Libraries
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

// Component

// Styles
import './style.css';

// Assets

const LineChart = () => {
    const [state, setState] = useState({
        series: [{
            name: 'Sales',
            data: [47, 45, 54, 38, 56, 24, 65, 31, 37, 39, 62, 51, 35, 41, 35, 27, 93, 53, 61, 27, 54, 43, 19, 46]
        }],
        options: {
            chart: {
                type: 'area',
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                curve: 'straight',
                width: 2,
            },
            fill: {
                gradient: {
                    enabled: true,
                    opacityFrom: 1,
                    opacityTo: .5
                }
            },
            labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
            yaxis: {
                min: 0
            },
            xaxis: {
                type: 'datetime',
            },
            colors: ['#DCE6EC'],
            title: {
                text: '',
                offsetX: 30,
                style: {
                    fontSize: '24px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            },
            subtitle: {
                text: '',
                offsetX: 30,
                style: {
                    fontSize: '14px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            }
            // stroke: {
            //     show: true,
            //     curve: 'smooth',
            //     lineCap: 'butt',
            //     colors: '#2AB57D',
            //     width: 2,
            //     dashArray: 0,
            // },
            // tooltip: {
            //     fixed: {
            //         enabled: false
            //     },
            //     x: {
            //         show: false
            //     },
            //     y: {
            //         title: {
            //             formatter: function (seriesName) {
            //                 return ''
            //             }
            //         }
            //     },
            //     marker: {
            //         show: false
            //     }
            // },
            // responsive: [
            //     {
            //         breakpoint: 1600,
            //         options: {
            //             chart: {
            //                 width: 120,
            //                 height: 35,
            //             },
            //         }
            //     },
            //     {
            //         breakpoint: 1400,
            //         options: {
            //             chart: {
            //                 width: 100,
            //                 height: 30,
            //             },
            //         }
            //     },
            //     {
            //         breakpoint: 991,
            //         options: {
            //             chart: {
            //                 width: 120,
            //                 height: 40,
            //             },
            //         }
            //     },
            //     {
            //         breakpoint: 576,
            //         options: {
            //             chart: {
            //                 width: 150,
            //                 height: 45,
            //             },
            //         }
            //     }
            // ]
        },
    })


    return (
        <ReactApexChart
            options={state.options}
            series={state.series}
            type="line"
            height={90}
            width='100%'
        />
    );
}


export default LineChart;
