import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';

import image from '../assets/images/user.jpg';

const onChange = (checked) => {
    console.log(`switch to ${checked}`);
};



export const blogCategoryArray = [
    { "name": 'Nutrition', "key": "Nutrition" },
    { "name": 'Recipes', "key": "Recipes" },
    { "name": 'Workouts', "key": "Workouts" },
    { "name": 'Reviews', "key": "Reviews" },
    { "name": 'Podcasts', "key": "Podcasts" },
    { "name": 'Music', "key": "Music" },
    { "name": 'News', "key": "News" },
]

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


export const columns = [
    {
        title: 'Image',
        dataIndex: 'image',
        render: (text, record) => <img src={image} />,
    },
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Author',
        dataIndex: 'author',
    },
    {
        title: 'Category',
        dataIndex: 'category',
    },
    {
        title: 'Video Link',
        dataIndex: 'video',
    },
    {
        title: 'Date & Time',
        dataIndex: 'dateTime',
    },
    {
        title: 'Short Description',
        dataIndex: 'short',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        render: (text, record) => <Switch defaultChecked onChange={onChange} />,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text, record) => {
            return (
                <div className='table-btns'>
                    <div className='table-btn edit-btn'>
                        <Link to='#'>
                            <Edit2 size={14} />
                        </Link>
                    </div>
                    <div className='table-btn delete-btn'>
                        <Link to='#'>
                            <Trash2 size={14} />
                        </Link>
                    </div>
                </div>
            )
        },
    },
];

export const data = [
    {
        key: '1',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '2',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '3',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '4',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '5',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '6',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '7',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '8',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '9',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '10',
        title: 'Cristofer Pass',
        author: 'ambassador',
        category: 'test',
        video: 'https://www.lipsum.com/',
        dateTime: moment().format('L - h:mm a'),
        short: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
]