import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';

import image from '../assets/images/user.jpg';

const onChange = (checked) => {
    console.log(`switch to ${checked}`);
};

export const columns = [
    {
        title: 'Image',
        dataIndex: 'image',
        render: (text, record) => <img src={image} />,
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
    },
    {
        title: 'Role',
        dataIndex: 'role',
    },
    {
        title: 'Register Country',
        dataIndex: 'country',
    },
    {
        title: 'Date & Time',
        dataIndex: 'dateTime',
    },
    {
        title: 'Facebook',
        dataIndex: 'facebook',
    },
    {
        title: 'Twitter',
        dataIndex: 'twitter',
    },
    {
        title: 'Linkedin',
        dataIndex: 'linkedin',
    },
    {
        title: 'Instagram',
        dataIndex: 'instagram',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        render: (text, record) => <Switch defaultChecked onChange={onChange} />,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text, record) => {
            return (
                <div className='table-btns'>
                    <div className='table-btn edit-btn'>
                        <Link to='#'>
                            <Edit2 size={14} />
                        </Link>
                    </div>
                    <div className='table-btn delete-btn'>
                        <Link to='#'>
                            <Trash2 size={14} />
                        </Link>
                    </div>
                </div>
            )
        },
    },
];

export const data = [
    {
        key: '1',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '2',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '3',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '4',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '5',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '6',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '7',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '8',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '9',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        key: '10',
        name: 'Cristofer Pass',
        email: 'info@username.com',
        phone: '+92 341 0566466',
        role: 'Pro',
        country: 'Pakistan, Punjab, Lahore',
        dateTime: moment().format('L - h:mm a'),
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
];