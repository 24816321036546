// Libraries
import { Link } from 'react-router-dom';
import { Typography, Breadcrumb } from 'antd';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const Header = props => {

    const { title, home, segmentOne, segmentTwo, path } = props;

    return (
        <div className='header'>
            <Title className='mb-0' level={3} type="primary">{title}</Title>
            <Breadcrumb>
                {home && <Breadcrumb.Item>
                    <Link to={"/"}>{home}</Link>
                </Breadcrumb.Item>}
                {segmentOne && <Breadcrumb.Item>
                    <Link to={path}>{segmentOne}</Link>
                </Breadcrumb.Item>}
                {segmentTwo && <Breadcrumb.Item>{segmentTwo}</Breadcrumb.Item>}
            </Breadcrumb>
        </div>
    )
}

export default Header;