// Libraries
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

// Components
import { Sponsors } from './components';
import { Header } from '../../components';

// Styles
import './style.css';

// Assets

const SponsorsPage = () => {
    return (
        <React.Fragment>
            <Header title="Sponsors" path="/" home="Home" segmentOne="Sponsors" />
            <div className='create-users create mt-30'>
                <Link to="/create-sponsor">
                    <Button type="primary" className='b-p-x'>Add new Sponsor</Button>
                </Link>
            </div>
            <Sponsors />
        </React.Fragment>
    )
}

export default SponsorsPage;
