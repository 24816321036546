// Libraries
import { Button, Col, Form, Input, Popconfirm, Row, Typography } from 'antd';
import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';
// Component
import { columns, data } from '../../../../data/BlogData';

// Styles
import './style.css';
import { AntTable } from '../../../../components';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { useEffect, useState } from 'react';
import OpenSuccessNotification from '../../../Notification/successNotif';
import useToken from '../../../../hooks/useToken';
import interceptor from '../../../../shared/interceptor';

// Assets

const { Title } = Typography;

const Blog = () => {
    const { token } = useToken();
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Image',
            dataIndex: 'pathFeaturedImage',
            render: (text, record) => <img src={text} />,
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Author',
            dataIndex: 'authorName',
        },
        {
            title: 'Category',
            dataIndex: 'category',
        },
        {
            title: 'Video Link',
            dataIndex: 'videoLink',
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            render: (text) => moment(text).format('L - h:mm a'),

        },
        {
            title: 'Short Description',
            dataIndex: 'shortDescription',
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'blogDetail',
        // },
        {
            title: 'Active',
            dataIndex: 'isActive',
            render: (text, record) =>
                <Popconfirm
                    title="Are you sure you want to disable.?"
                    onConfirm={() => {
                        setLoading(true)
                        interceptor.axiosPut("put", "/blogs/activeInactive/" + record._id + "/" + !text, {}, token).then(res => {
                            console.log(res)
                            if (res.status == 200) {
                                setLoading(false)
                                OpenSuccessNotification("topRight", "updated successfully")
                                dispatcher(actionsApi.getBlogs(token))
                            }
                        })
                    }}

                >
                    <Switch key={record._id} checked={text}
                    />
                </Popconfirm>



        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => {
                return (
                    <div className='table-btns'>
                        <div className='table-btn edit-btn'>

                            <Link to={`/edit-blog-post/${record?._id}`} >
                                <Edit2 size={14} onClick={() => {
                                    console.log(record)
                                }} />
                            </Link>
                        </div>
                        <div className='table-btn delete-btn'>
                            <Popconfirm
                                title="Are you sure you want to delete.?"
                                onConfirm={() => {
                                    setLoading(true)
                                    interceptor.axiosDelete("delete", "/blogs/delete/" + record._id, {}, token).then(res => {
                                        console.log(res)
                                        if (res.status == 200) {
                                            setLoading(false)
                                            OpenSuccessNotification("topRight", "Deleted Successfully")
                                            dispatcher(actionsApi.getBlogs(token))
                                        }
                                    })
                                }}

                            >
                                <Link to='#'>
                                    <Trash2 size={14} />
                                </Link>
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ];

    const [model, setModel] = useState(null)
    const dispatcher = useShareDispatch();
    const { blogs, isLoadingblogs } = useShareSelector(state => state.blogs);
    const [filterlist, setFilterlist] = useState(null);
    const onFinish = (values) => {
        console.log('Success:', values);
        let filter = model
        if (values.title) {
            filter = filter.filter(x => x.title.toLowerCase().includes(values.title.toLowerCase()))
        }
        if (values.authorName) {
            filter = filter.filter(x => x.authorName.toLowerCase().includes(values.authorName.toLowerCase()))
        }
        setFilterlist(filter)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        if (blogs) {
            setModel(blogs)
            setFilterlist(blogs)
        }
        else {
            dispatcher(actionsApi.getBlogs(token))
        }
    }, [blogs])
    return (
        <>
        <div className='fs-container'>
            <Form
                name="fitnessProForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row gutter={25}>

                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            name="title"
                        >
                            <Input placeholder='Title' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            name="authorName"
                        >
                            <Input placeholder='Author' />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='fs-right'>
                    {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button> */}
                    <Button type="primary" htmlType="submit">Search</Button>
                </div>
            </Form>
        </div>
        <div className='blog-table table-view rounded-box mt-30'>
            <Title className='mb-0' level={4} type="primary">All Blog Posts</Title>
            <div className='ant-table-block'>
                <AntTable
                    rowClassName="blog"
                    scroll={3000}
                    columns={columns}
                    data={filterlist}
                    loading={isLoadingblogs || loading}
                />
            </div>
        </div>
        </>
    )
}

export default Blog;
