// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { CreateUser } from './components';

// Styles
import './style.css';

// Assets

const CreateUserPage = () => {
    return (
        <React.Fragment>
          <Header title="Create User" home="Home" path="/users" segmentOne="User" segmentTwo="Create User" />
            <CreateUser />
        </React.Fragment>
    )
}

export default CreateUserPage;
