import { configureStore } from "@reduxjs/toolkit";
import userList from "./actions/user/userDetail";
import UserRoleDetails from "./actions/user/UserRoleDetails";
import landingPageDetail from "./actions/landingPage/landingPageDetail";
import ambassadors from "./actions/landingPage/ambassadors";
import sponsorData from "./actions/landingPage/sponser";
import collaborators from "./actions/landingPage/collaborator";
import blogs from "./actions/landingPage/blog";


const store = configureStore({
	reducer: {
		userList,
		UserRoleDetails,
		landingPageDetail,
		ambassadors,
		sponsorData,
		collaborators,
		blogs
	},

	// middleware:[...getDefaultMiddleware,loggerMidleWare]
	// The thunk middleware was automatically added
	// https://github.com/reduxjs/redux-thunk
});

export default store;

