// Libraries
import { Button, Col, Form, Input, Popconfirm, Row, Spin, Table, Typography } from 'antd';

// Component
import { columns } from '../../../../data/AmbassadorsData';
import { Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';
// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useEffect, useState } from 'react';
import interceptor from '../../../../shared/interceptor';
import OpenSuccessNotification from '../../../Notification/successNotif';
import Title from 'antd/lib/skeleton/Title';

// Assets


const Collaborators = () => {
    const { token } = useToken();
    const [loading, setLoading] = useState(false)
    const columns = [
        {
            title: 'Image',
            dataIndex: 'path',
            render: (text, record) => <img src={text} />,
        },
        {
            title: 'Name',
            dataIndex: 'title',
        },
        {
            title: 'Sub Title',
            dataIndex: 'subTitle',
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            render: (text) => moment(text).format('L - h:mm a'),
        },
        {
            title: 'Facebook',
            dataIndex: 'facebookUrl',
        },
        {
            title: 'Twitter',
            dataIndex: 'twitterUrl',
        },
        {
            title: 'YouTube',
            dataIndex: 'youtubeUrl',
        },
        {
            title: 'Instagram',
            dataIndex: 'instagramUrl',
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        // },
        {
            title: 'Active',
            dataIndex: 'isActive',
            render: (text, record) =>
                <Popconfirm
                    title="Are you sure you want to disable.?"
                    onConfirm={() => {
                        setLoading(true)
                        interceptor.axiosPut("put", "/collaborators/activeInactive/" + record._id + "/" + !text, {}, token).then(res => {
                            console.log(res)
                            if (res.status == 200) {
                                setLoading(false)
                                OpenSuccessNotification("topRight", "updated successfully")
                                dispatcher(actionsApi.getcollaborators(token))
                            }
                        })
                    }}

                >
                    <Switch key={record._id} checked={text}
                    />
                </Popconfirm>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => {
                return (
                    <div className='table-btns'>
                        <div className='table-btn edit-btn'>
                            <Link to={`/edit-collaborator/${record?._id}`} >
                                <Edit2 size={14} onClick={() => {
                                }} />
                            </Link>
                        </div>
                        <div className='table-btn delete-btn'>
                            <Popconfirm
                                title="Are you sure you want to delete.?"
                                onConfirm={() => {
                                    setLoading(true)
                                    interceptor.axiosDelete("delete", "/collaborators/delete/" + record._id, {}, token).then(res => {
                                        console.log(res)
                                        if (res.status == 200) {
                                            setLoading(false)
                                            OpenSuccessNotification("topRight", "Deleted Successfully")
                                            dispatcher(actionsApi.getcollaborators(token))
                                        }
                                    })
                                }}

                            >
                                <Link to='#'>
                                    <Trash2 size={14} />
                                </Link>
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ];
    const [model, setModel] = useState(null)
    const dispatcher = useShareDispatch();
    const { collaborators, isLoadingCollaborator } = useShareSelector(state => state.collaborators);
    const [filterlist, setFilterlist] = useState(null);


    useEffect(() => {
        if (collaborators) {
            setModel(collaborators)
            setFilterlist(collaborators)
        }
        else {
            dispatcher(actionsApi.getcollaborators(token))
        }
    }, [collaborators])

    const onFinish = (values) => {
        console.log('Success:', values);
        let filter = model
        if (values.title) {
            filter = filter.filter(x => x.title.toLowerCase().includes(values.title.toLowerCase()))
        }
        if (values.subTitle) {
            filter = filter.filter(x => x.subTitle.toLowerCase().includes(values.subTitle.toLowerCase()))
        }
        setFilterlist(filter)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={25}>

                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="title"
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="subTitle"
                            >
                                <Input placeholder='Sub Title' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button> */}
                        <Button type="primary" htmlType="submit">Search</Button>
                    </div>
                </Form>
            </div>
            <div className='collaborators-table table-view rounded-box mt-30'>
                <Title className='mb-0' level={4} type="primary">All Collaborators</Title>
                <div className='ant-table-block'>
                    <Table
                        rowClassName="collaborators"
                        scroll={{
                            x: 1300,
                        }}
                        columns={columns}
                        dataSource={filterlist}
                        loading={isLoadingCollaborator || loading}
                    />
                </div>
            </div>
        </>
    )
}

export default Collaborators;
