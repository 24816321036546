// Libraries
import React from 'react';

// Components
import { Header } from '../../components';
import { CreateAmbassador } from './components';

// Styles
import './style.css';

// Assets

const CreateSponsorPage = () => {
    return (
        <React.Fragment>
            <Header title="Create Sponsor" home="Home"   path="/sponsors" segmentOne="Sponsor" segmentTwo="Create Sponsor" />
            <CreateAmbassador />
        </React.Fragment>
    )
}

export default CreateSponsorPage;
