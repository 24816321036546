// Libraries
import React from 'react';
import { Tabs } from 'antd';

// Components
import { Header } from '../../components';
import { Home, Social, Terms, Privacy } from './components';

// Styles
import './style.css';

// Assets

const { TabPane } = Tabs;

const LandingPage = () => {
    return (
        <React.Fragment>
            <Header title="Static Pages" path="/" home="Home" segmentOne="Static Pages" />
            <Tabs defaultActiveKey="1" className='mt-30'>
                <TabPane tab="Landing Page Details" key="1">
                    <Home />
                </TabPane>
                <TabPane tab="Social Accounts & Contact Details" key="2">
                    <Social />
                </TabPane>
                <TabPane tab="Terms & Conditions Details" key="3">
                    <Terms />
                </TabPane>
                <TabPane tab="Privacy Policy Details" key="4">
                    <Privacy />
                </TabPane>
            </Tabs>
        </React.Fragment>
    )
}

export default LandingPage;
