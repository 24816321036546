// Libraries
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Button, Upload } from 'antd';

// Component

// Styles
import './style.css';
import interceptor from '../../../../shared/interceptor';
import { Navigate, useNavigate } from 'react-router-dom';
import useToken from '../../../../hooks/useToken';
import OpenSuccessNotification from '../../../Notification/successNotif';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import domainurl from '../../../../constant/domainurl';
import { useParams } from 'react-router-dom';
// Assets
const { Option } = Select;

const EditCollaborator = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoader, setIsLoader] = useState(false)
    const { token } = useToken();
    const [fileList, setfileList] = useState();
    const [model, setModel] = useState();
    const dispatcher = useShareDispatch();

    const onFinish = (values) => {
        console.log(values);
        setIsLoader(true)
        interceptor.axiosPost("post", "/collaborators/edit", values, token).then(res => {
            console.log(res)
            if (res.status == 200) {
                if (fileList[0] && fileList[0].originFileObj) {
                    values.file = fileList[0].originFileObj
                    uploadImage(values)
                }
                else {
                    fetch(model.path)
                        .then(async response => {
                            const contentType = response.headers.get('content-type')
                            const blob = await response.blob()
                            let file = new File([blob], "image." + model.path.split('.')[1], { contentType })
                            console.log(file)
                            values.file = file
                            uploadImage(values)
                        })
                }

            }
        })
    }


    const uploadImage = (res) => {
        console.log(res)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", res.file)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/collaborators/upload/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoader(false)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    navigate('/collaborators')
                    dispatcher(actionsApi.getCollaborators(token))

                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (id) {
            interceptor.axiosGet("get", "/collaborators/" + id, token).then(res => {
                console.log(res.data.result)
                if (res.status == 200) {
                    setIsLoader(false)
                    form.setFieldsValue(res.data.result[0])
                    setfileList([{
                        url: res.data.result[0].path
                    }])

                    setModel(res.data.result[0])

                }
            })
        }

    }, [id])
    return (
        <div className='add-volunteer mt-30'>
            <Form form={form} layout="vertical" autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 24,
                    lg: 30,
                }}>
                    <Col xs={24}>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            maxCount={1}
                            fileList={fileList}
                            className="upload-image"
                            onChange={(file) => {
                                setfileList(file.fileList);
                            }}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            <Button>Upload Image</Button>
                        </Upload>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item className='fs-dn' name="_id" >
                        </Form.Item>
                        <Form.Item name="title" label="Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="subTitle" label="Sub Title">
                            <Input placeholder="Sub Title" />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="dateTime" label="Date & Time">
                            <DatePicker
                                showTime
                                placeholder="Select Date & Time"
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="facebookUrl" label="Facebook">
                            <Input placeholder="Facebook Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="twitterUrl" label="Twitter">
                            <Input placeholder="Twitter Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="youtubeUrl" label="YouTube">
                            <Input placeholder="YouTube" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="instagramUrl" label="Instagram">
                            <Input placeholder="Instagram Link" />
                        </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                        <Form.Item name="description" label="Description">
                            <Input.TextArea allowClear placeholder='Description' />
                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <div className='form-btn'>
                            <Button type="primary" htmlType='submit' loading={isLoader} className='b-p-x'>Update</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}


export default EditCollaborator;
