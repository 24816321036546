// Libraries
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Select, Button, Upload } from 'antd';

// Component

// Styles
import './style.css';
import domainurl from '../../../../constant/domainurl';
import OpenSuccessNotification from '../../../Notification/successNotif';
import { useNavigate, useParams } from 'react-router-dom';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { blogCategoryArray } from '../../../../data/BlogData';

// Component

// Styles
import './style.css';
import interceptor from '../../../../shared/interceptor';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// Assets
const { Option } = Select;

const EitBlogPost = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoader, setIsLoader] = useState(false)
    const { token } = useToken();
    const [authorImage, setauthorImage] = useState();
    const [featuredImage, setfeaturedImage] = useState();
    const [secoundFeaturedImage, setsecoundFeaturedImage] = useState();
    const dispatcher = useShareDispatch();
    const [model, setModel] = useState();


    const [shortDesc, setShortDesc] = useState(
        () => EditorState.createEmpty(),
    );
    const [longDesc, setLongDesc] = useState(
        () => EditorState.createEmpty(),
    );


    const onFinish = (value) => {
        console.log(value);
        setIsLoader(true)
        value._id = value._id ? value._id : "-1"

        var Promisi1 = new Promise((resolve) => {
            if (authorImage && authorImage[0] && authorImage[0].originFileObj) {
                value.authorImage = authorImage[0].name.split(".")[0];
                value.authorImagefile = authorImage[0].originFileObj
                resolve();
            }
            else {
                if (model.pathAuthorImage) {

                    value.authorImage = "authorImage"
                    fetch(model.pathAuthorImage)
                        .then(async response => {
                            const contentType = response.headers.get('content-type')
                            const blob = await response.blob()
                            let file = new File([blob], "authorImage." + model.pathAuthorImage.split('.').pop(), { contentType })
                            value.authorImagefile = file
                            resolve();
                        })
                } else {
                    resolve();

                }
            }
        })
        var Promisi2 = new Promise((resolve) => {
            if (secoundFeaturedImage && secoundFeaturedImage[0] && secoundFeaturedImage[0].originFileObj) {
                value.secoundFeaturedImage = secoundFeaturedImage[0].name.split(".")[0];
                value.secoundFeaturedImagefile = secoundFeaturedImage[0].originFileObj
                resolve();
            }
            else {

                if (model.pathSecoundFeaturedImage) {
                    value.secoundFeaturedImage = "secoundFeaturedImage"

                    fetch(model.pathSecoundFeaturedImage)
                        .then(async response => {
                            const contentType = response.headers.get('content-type')
                            const blob = await response.blob()
                            let file = new File([blob], "secoundFeaturedImage." + model.pathSecoundFeaturedImage.split('.').pop(), { contentType })
                            value.secoundFeaturedImagefile = file
                            resolve();
                        })
                } else {
                    resolve();

                }

            }
        })

        var Promisi3 = new Promise((resolve) => {
            if (featuredImage && featuredImage[0] && featuredImage[0].originFileObj) {
                value.featuredImage = featuredImage[0].name.split(".")[0];
                value.featuredImagefile = featuredImage[0].originFileObj
                resolve();
            }
            else {

                if (model.pathFeaturedImage) {

                    value.featuredImage = "featuredImage"
                    fetch(model.pathFeaturedImage)
                        .then(async response => {
                            const contentType = response.headers.get('content-type')
                            const blob = await response.blob()
                            let file = new File([blob], "featuredImage." + model.pathFeaturedImage.split('.').pop(), { contentType })
                            value.featuredImagefile = file;
                            resolve();
                        })
                } else {
                    resolve();

                }
            }

        })


        Promise.all([Promisi1, Promisi2, Promisi3]).then(item => {

            const rawContentState = draftToHtml(convertToRaw(shortDesc.getCurrentContent()));
            value.shortDescription = JSON.stringify(rawContentState)
            const rawContentState2 = draftToHtml(convertToRaw(longDesc.getCurrentContent()));
            value.blogDetail = JSON.stringify(rawContentState2)
            uploadImage(value)
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const uploadImage = (res) => {
        console.log(res)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();

        if (res.featuredImagefile) { formdata.append("image", res.featuredImagefile) }
        if (res.secoundFeaturedImagefile) { formdata.append("image", res.secoundFeaturedImagefile) }
        if (res.authorImagefile) { formdata.append("image", res.authorImagefile) }
        formdata.append("request", JSON.stringify(res))
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/blogs/create/" + res._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setIsLoader(false)
                if (res.status == "ok") {
                    OpenSuccessNotification("topRight", res.message)
                    navigate('/blog')
                    dispatcher(actionsApi.getBlogs(token))

                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })
    }


    useEffect(() => {
        if (id) {
            interceptor.axiosGet("get", "/blogs/" + id, token).then(res => {
                console.log(res.data.result)
                if (res.status == 200) {
                    setIsLoader(false)
                    debugger
                    form.setFieldsValue(res.data.result[0])
                    if (res.data.result[0].pathFeaturedImage) {
                        setfeaturedImage([{
                            url: res.data.result[0].pathFeaturedImage
                        }])
                    }
                    if (res.data.result[0].pathSecoundFeaturedImage) {
                        setsecoundFeaturedImage([{
                            url: res.data.result[0].pathSecoundFeaturedImage
                        }])
                    }
                    if (res.data.result[0].pathAuthorImage) {
                        setauthorImage([{
                            url: res.data.result[0].pathAuthorImage
                        }])
                    }
                    if (res.data.result[0].shortDescription) {
                        const contentBlocks = convertFromHTML(JSON.parse(res.data.result[0].shortDescription));
                        const contentState = ContentState.createFromBlockArray(
                            contentBlocks.contentBlocks,
                            contentBlocks.entityMap
                        );
                        setShortDesc(EditorState.createWithContent(contentState));
                    }
                    if (res.data.result[0].blogDetail) {
                        const contentBlocks = convertFromHTML(JSON.parse(res.data.result[0].blogDetail));
                        const contentState = ContentState.createFromBlockArray(
                            contentBlocks.contentBlocks,
                            contentBlocks.entityMap
                        );
                        setLongDesc(EditorState.createWithContent(contentState));
                    }



                    setModel(res.data.result[0])
                }
            })
        }

    }, [id])
    return (
        <div className='add-volunteer mt-30'>
            <div className='add-volunteer mt-30'>
                <Form form={form} layout="vertical" autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={{
                        xs: 20,
                        sm: 20,
                        md: 24,
                        lg: 30,
                    }}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item className='fs-dn' name="_id" >
                            </Form.Item>
                            <label style={{ marginBottom: '15px', display: 'block' }}>Upload Featured Image</label>
                            <Form.Item name="featuredImage" >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    maxCount={1}
                                    fileList={featuredImage}
                                    className="upload-image"
                                    onChange={(file) => {
                                        setfeaturedImage(file.fileList);
                                    }}
                                    beforeUpload={(file) => {
                                        return false
                                    }}
                                >
                                    <Button>Upload Image</Button>
                                </Upload>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <label style={{ marginBottom: '15px', display: 'block' }}>Upload Author Image</label>
                            <Form.Item name="featuredImage" >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    maxCount={1}
                                    className="upload-image"
                                    fileList={authorImage}
                                    onChange={(file) => {
                                        setauthorImage(file.fileList);
                                    }}
                                    beforeUpload={(file) => {
                                        return false
                                    }}
                                >
                                    <Button>Upload Image</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <label style={{ marginBottom: '15px', display: 'block' }}>Upload Secound Featured Image</label>
                            <Form.Item name="featuredImage" >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    maxCount={1}
                                    fileList={secoundFeaturedImage}
                                    className="upload-image"
                                    onChange={(file) => {
                                        setsecoundFeaturedImage(file.fileList);
                                    }}
                                    beforeUpload={(file) => {
                                        return false
                                    }}
                                >
                                    <Button>Upload Image</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item className='fs-dn' name="_id" >
                            </Form.Item>
                            <Form.Item name="title" label="Title">
                                <Input placeholder="Title" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="authorName" label="Author">
                                <Input placeholder="Author" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="category" label="Category">
                                <Select placeholder="Category"
                                >
                                    {blogCategoryArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Form.Item name="videoLink" label="Video Link">
                                <Input placeholder="Video Link" />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Form.Item name="dateTime" label="Date & Time">
                        <DatePicker
                            showTime
                            placeholder="Select Date & Time"
                            style={{
                                width: '100%',
                            }} />
                    </Form.Item>
                </Col> */}
                        <Col span={24}>
                            <Form.Item name="shortDescription" label="Short Description">
                                <Editor
                                    editorState={shortDesc}
                                    onEditorStateChange={setShortDesc}
                                />
                                {/* <Input.TextArea allowClear placeholder='Input Short Description' /> */}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="blogDetail" label="Description">
                                <Editor
                                    editorState={longDesc}
                                    onEditorStateChange={setLongDesc}
                                />
                                {/* <Input.TextArea allowClear placeholder='Input Description' /> */}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className='form-btn'>
                                <Button type="primary" htmlType='submit' loading={isLoader} className='b-p-x'>Update</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default EitBlogPost;
