// Libraries
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';

// Component
import { LineChart } from '../../../../components';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch } from '../../../../shared';
import interceptor from '../../../../shared/interceptor';

// Styles
import './style.css';

// Assets

const Analytics = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatcher = useShareDispatch();
    const { token } = useToken();

    const [model, setModel] = useState(null);

    useEffect(() => {
        setIsLoading(true)

        interceptor.axiosGet("get", "/dashboard/getWidgetData", token).then(res => {
            console.log(res.data.result)
            if (res.status == 200) {
                setIsLoading(false)
                setModel(res.data.result)
            }
        })
    }, [])
    return (
        <div className='analytics-row'>
            <Row gutter={{
                xs: 20,
                sm: 20,
                md: 24,
                lg: 30,
            }}>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-plus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Actions Users</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.users}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-plus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Blogs</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.blogs}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-minus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Ambassadors</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.ambassadors}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-plus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Sponsors</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.sponsors}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-plus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Fitness Pro</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.FPuser}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-plus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Fitness Model</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.FMuser}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div className="analytics analytics-plus">
                        <div className='analytics-card'>
                            <div className='analytics-title'>Total Fitness Center</div>
                            <div className='anlytics-middle'>
                                <div className='analytics-value'>{model && model.FCuser}</div>
                                <div className='analytics-meta'>
                                    <div className='analytics-percent'>+20%</div>
                                    <div className='analytics-days'>Since last week</div>
                                </div>
                            </div>
                        </div>
                        <div className='analytics-chart'>
                            <LineChart />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Analytics;
